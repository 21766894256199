import React from 'react'

const Loading = (props) => {
  return (
    <div className={`ui__loading ${props.className}`}>
      <div>
        <div className="ui__loading__rect ui__loading__rect--1" />
        <div className="ui__loading__rect ui__loading__rect--2" />
        <div className="ui__loading__rect ui__loading__rect--3" />
        <div className="ui__loading__rect ui__loading__rect--4" />
        <div className="ui__loading__rect ui__loading__rect--5" />
      </div>
      {props.text && <div className="ui__loading__text">{props.text}</div>}
    </div>
  )
}

export default Loading
