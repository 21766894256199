import React, { Component } from 'react'
import { reduxForm } from 'redux-form'
import { connect } from 'react-redux'

// import sanitizeHtml from 'sanitize-html'

// import { SlideDown } from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'

import OrderSummary from '../component/services/order-summary'
import { parseContent } from '../utils/functions'
import { ALLOWED_ATTRIBUTES, ALLOWED_TAGS } from '../conf/constants'
// import // renderReactAsyncSelect,
// renderSelectWithHelp,
// renderDatePicker,
// renderField,
// renderSelect,
// preBankCodeValidation,
// bankAccountValidation,
// bankCodeValidation,
// required,
// '../utils/form'

// import { findAddress } from '../actions/ruian'
// import { bankCodes } from '../conf/bank-codes'
// import { ALLOWED_ATTRIBUTES, ALLOWED_TAGS } from '../conf/constants'

// let findAddressInterval
// let withValue = false

class UpsellAgreementForm2 extends Component {
  constructor(props) {
    super(props)

    // this.toggleSameAddress = this.toggleSameAddress.bind(this)
    // this.findAddress = this.findAddress.bind(this)

    // let invoiceTypeSelected
    // let invoiceDeliverySelected
    // const invoiceTypeSelectOptions = Object.entries(
    //   props.invoiceTypeOptions
    // ).map((o) => {
    //   if (o[1].selected) invoiceTypeSelected = o[0]
    //   return { label: o[1].name, value: o[0] }
    // })
    // const invoiceDeliverySelectOptions = Object.entries(
    //   props.invoiceDeliveryOptions
    // ).map((o) => {
    //   if (o[1].selected) invoiceDeliverySelected = o[0]
    //   return { label: o[1].name, value: o[0] }
    // })

    // props.change('invoice_type', invoiceTypeSelected)
    // props.change('invoice_delivery', invoiceDeliverySelected)

    const td = new Date()

    this.state = {
      birthDate: new Date(td.getFullYear() - 18, td.getMonth(), td.getDay()),
      sameAddress: true,
      sameAddressShipping: true,
      // invoiceTypeSelectOptions,
      // invoiceDeliverySelectOptions,
    }

    // props.change('address_same', this.state.sameAddress)
    // props.change('address_same_shipping', this.state.sameAddressShipping)
  }

  // toggleSameAddress(address) {
  //   this.setState({
  //     [address]: !this.state[address],
  //   })
  // }

  // findAddress(address, cb) {
  //   if (address.length < 3) return

  //   if (findAddressInterval !== null) {
  //     clearInterval(findAddressInterval)
  //   }
  //   findAddressInterval = setTimeout(() => {
  //     findAddress({ query: address })
  //       .then((resp) => {
  //         if (!Array.isArray(resp.data)) return
  //         cb(
  //           resp.data.map((a) => ({
  //             label: `${a.street} ${a.houseNumber}${
  //               a.orientationNumber > 0 ? '/' + a.orientationNumber : ''
  //             }, ${a.city}, ${a.cityPart}`,
  //             value: a.id,
  //           }))
  //         )
  //       })
  //       .catch((err) => {})
  //   }, 400)
  // }

  render() {
    const {
      goToStep,
      handleSubmit,
      onSubmit,
      toggleModal,
      // help,
      portfolioKey,
      content,
      // selectedInvoiceType,
      // isGoPay,
    } = this.props

    // const codes = bankCodes.map((code) => `${code.code} (${code.name})`)

    // withValue =
    //   this.props.invoiceTypeOptions[this.props.selectedInvoiceType] &&
    //   this.props.invoiceTypeOptions[this.props.selectedInvoiceType].value

    return (
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="order__agreement small-note"
      >
        <OrderSummary
          toggleModal={toggleModal}
          portfolioKey={portfolioKey}
          isUpsell={true}
        />

        <br />
        <p
          dangerouslySetInnerHTML={{
            __html: parseContent(
              content[`content.upsell.secondstep.commitment.info`],
              {
                html: true,
                allowedTags: ALLOWED_TAGS,
                allowedAttributes: ALLOWED_ATTRIBUTES,
              }
            ),
          }}
        />

        <div className="footline footline--activation footline footline--nextprev">
          <button
            className="btn btn--secondary"
            type="button"
            onClick={() => {
              goToStep(1)
            }}
          >
            Zpět
          </button>
          <button className="btn btn--primary" type="submit">
            Pokračovat na smluvní dokumenty
          </button>
        </div>
      </form>
    )
  }
}

// const validate = (values) => {
//   const errors = {}

//   if (!values.dob) {
//     errors.dob = 'Prosím, vyplňte datum.'
//   }

//   if (!values.address) {
//     errors.address = 'Prosím, zadejte adresu.'
//   }

//   if (!values.address_same && !values.address_installation) {
//     errors.address_installation = 'Prosím, zadejte adresu.'
//   }

//   if (!values.address_same_shipping && !values.address_shipping) {
//     errors.address_shipping = 'Prosím, zadejte adresu.'
//   }

//   if (withValue && !values.invoice_type_value) {
//     errors.invoice_type_value = 'Prosím, zadejte hodnotu.'
//   }

//   // if (withValue && values.invoice_type === 'sipo' && values.bankPre) {
//   //   errors.invoice_type_value = 'Prosím, zadejte hodnotu.'
//   // }

//   return errors
// }

UpsellAgreementForm2 = reduxForm({
  form: 'service_upsell_agreement_form_u',
  touchOnBlur: false,
  // enableReinitialize: true,
  destroyOnUnmount: false,
  // validate,
  // onSubmitFail: (e) => {
  //   console.log(e)
  //   if (e && (e.hasOwnProperty('address') || e.hasOwnProperty('dob'))) {
  //     window.scrollTo(0, 0)
  //   }
  // },
})(UpsellAgreementForm2)

class ServicesActivationStep2Upsell extends Component {
  render() {
    const {
      goToStep,
      requestFindAddress,
      handleSubmit,
      toggleModal,
      content,
      help,
      portfolioKey,
      invoiceType,
      invoiceDelivery,
      selectedInvoiceType,
      isGoPay,
    } = this.props
    return (
      <div className="activation activation--3 order">
        <h2 className="h2">
          {parseContent(content[`content.upsell.secondstep.title`], {
            html: true,
            allowedTags: [],
            allowedAttributes: [],
          })}
        </h2>
        <p
          dangerouslySetInnerHTML={{
            __html: parseContent(
              content[`content.upsell.secondstep.description`],
              {
                html: true,
                allowedTags: ALLOWED_TAGS,
                allowedAttributes: ALLOWED_ATTRIBUTES,
              }
            ),
          }}
        ></p>
        <UpsellAgreementForm2
          goToStep={goToStep}
          onSubmit={handleSubmit}
          requestAddress={requestFindAddress}
          toggleModal={toggleModal}
          portfolioKey={portfolioKey}
          content={content}
          help={help}
          invoiceTypeOptions={invoiceType}
          invoiceDeliveryOptions={invoiceDelivery}
          selectedInvoiceType={selectedInvoiceType}
          isGoPay={isGoPay}
        />
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    content: state.translation.content,
    help: state.translation.help,
    invoiceType: state.contract_new.overview.invoiceTypeOptions || {},
    invoiceDelivery: state.contract_new.overview.invoiceDeliveryOptions || {},
    selectedInvoiceType:
      (state.form.service_upsell_agreement_form &&
        state.form.service_upsell_agreement_form.values &&
        state.form.service_upsell_agreement_form.values.invoice_type) ||
      null,
    isGoPay: state.contract_new.overview.priceVat > 0,
  }
}

export default connect(mapStateToProps, {})(ServicesActivationStep2Upsell)
