import React from 'react'
import tellyLogo from '../assets/images/logo-blue.svg'

const ModalLogo = () => {
  return (
    <div className="popup-headline">
      <div className="row">
        <div className="col col--is--12 col--xm--5">
          <div className="intro__header__logo">
            <img src={tellyLogo} alt="intro logo" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ModalLogo
