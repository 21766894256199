import React, { Component } from 'react'
//import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import PasswordCreateForm from '../../form/password-create-form'
import { requestCreatePassword } from '../../actions/login'
import PublicLayout from '../../layout/public-layout'
import tellyLogo from '../../assets/images/logo.svg'

class CreatePassword extends Component {
  constructor(props) {
    super(props)

    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleSubmitSuccess = this.handleSubmitSuccess.bind(this)

    let params = new URL(document.location).searchParams
    let hash = params.get('hash')
    let email = params.get('email')

    this.state = {
      successMessage: null,
      hash: hash,
      email: email,
    }
  }

  handleSubmit(values) {
    this.setState({
      successMessage: null,
    })
    values.hash = this.state.hash
    values.email = this.state.email
    return this.props.requestCreatePassword(values)
  }

  handleSubmitSuccess(result, dispatch, props) {
    this.setState({
      successMessage: (
        <div className="">
          Heslo bylo vytvořeno. Nyní se můžete <Link to="/">přihlásit</Link>
        </div>
      ),
    })
  }

  render() {
    return (
      <PublicLayout>
        {!this.state.hash ? (
          <div>
            <div className="intro__header">
              <div className="row">
                <div className="col col--is--5">
                  <img src={tellyLogo} alt="intro logo" />
                </div>
                <div className="col col--is--7">
                  <h1 className="text--right">Vytvoření hesla</h1>
                </div>
              </div>
            </div>
            <div className="spacer spacer--20 validation--block alert">
              Neplatný odkaz
            </div>
            <div className="intro__links">
              <Link className="a--arrow--back green intro__links__link" to="/">
                Zpět na přihlášení
              </Link>
              <Link
                className="a--arrow green intro__links__link"
                to="/zavolejte-mi"
              >
                Zavolejte mi
              </Link>
            </div>
          </div>
        ) : (
          <PasswordCreateForm
            successMessage={this.state.successMessage}
            onSubmit={this.handleSubmit}
            onSubmitSuccess={this.handleSubmitSuccess}
            content={this.props.content}
          />
        )}
      </PublicLayout>
    )
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    content: state.translation.content,
  }
}

export default connect(
  mapStateToProps,
  {
    requestCreatePassword,
  }
)(CreatePassword)
