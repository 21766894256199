import {
  REQUEST_CONTRACT_INVOICES,
  RECEIVE_CONTRACT_INVOICES,
  RECEIVE_CONTRACT_INVOICES_FAILED,
  REQUEST_CONTRACT_INVOICE,
  RECEIVE_CONTRACT_INVOICE,
  RECEIVE_CONTRACT_INVOICE_FAILED,
} from '../actions/types'

const INITIAL_STATE = {
  loading: false,
}

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case REQUEST_CONTRACT_INVOICE:
    case REQUEST_CONTRACT_INVOICES:
      return {
        ...state,
        loading: true,
      }
    case RECEIVE_CONTRACT_INVOICE:
      return {
        ...state,
        loading: false,
        [action.contract_id]: {
          ...state[action.contract_id],
        },
      }
    case RECEIVE_CONTRACT_INVOICES:
      let items = {}
      let ids = []

      for (let value of action.payload) {
        items[value.id] = value
        ids.push(value.id)
      }

      return {
        ...state,
        loading: false,
        [action.contract_id]: {
          items: items,
          ids: ids,
          meta: action.meta,
        },
      }
    case RECEIVE_CONTRACT_INVOICE_FAILED:
    case RECEIVE_CONTRACT_INVOICES_FAILED:
      return {
        ...state,
        loading: false,
      }
    // no default
  }
  return state
}
