import {
  MESSAGE_ERROR,
  // MESSAGE_INFO,
  // MESSAGE_SUCCESS,
  // MESSAGE_WARNINGd
} from '../conf/constants'

import {
  RECEIVE_INBOX,
  RECEIVE_INBOX_NEW,
  RECEIVE_INBOX_OPENED,
  REQUEST_INBOX,
  RECEIVE_INBOX_FAILED,
  RECEIVE_INBOX_MESSAGE,
  // REQUEST_INBOX_MESSAGE,
  RECEIVE_INBOX_MESSAGE_FAILED,
  SET_MESSAGE,
} from './types'

import { instance } from '../conf/axios'
//import MockAdapter from 'axios-mock-adapter';
//let mock = new MockAdapter(instance);

export function requestCustomerInboxMessage(message_id) {
  return function(dispatch, getState) {
    const { detail } = getState().inbox

    if (detail[message_id]) {
      return detail[message_id]
    }

    return instance
      .get(`customer/inbox/${message_id}`)
      .then((response) => {
        dispatch({
          type: RECEIVE_INBOX_MESSAGE,
          payload: response.data,
        })

        return Promise.resolve(response.data)
      })
      .catch((error) => {
        dispatch({
          type: SET_MESSAGE,
          payload: error,
          kind: MESSAGE_ERROR,
        })

        dispatch({
          type: RECEIVE_INBOX_MESSAGE_FAILED,
        })

        return Promise.reject(error)
      })
  }
}

export function requestCustomerInbox(page = 1, messageState = null) {
  return function(dispatch, getState) {
    const messageStates = {
      nove: {
        filter: 'new',
        returnType: RECEIVE_INBOX_NEW,
      },
      prectene: {
        filter: 'opened',
        returnType: RECEIVE_INBOX_OPENED,
      },
    }

    ///const messageFilter = messageStates[messageState] ? messageStates[messageState].filter : messageState
    const returnType = messageStates[messageState]
      ? messageStates[messageState].returnType
      : RECEIVE_INBOX

    dispatch({
      type: REQUEST_INBOX,
    })

    let params = {
      page: page,
    }

    if (messageState && messageStates[messageState]) {
      params = {
        ...params,
        filter: messageStates[messageState].filter,
      }
    }

    //return instance.get(`customer/inbox?page=${page}${messageFilter ? `&filter=${messageFilter}` : ''}`).then( response => {
    return instance
      .get(`customer/inbox`, { params: params })
      .then((response) => {
        dispatch({
          type: returnType,
          payload: response.data,
          page: page,
        })
        return Promise.resolve(response.data)
      })
      .catch((error) => {
        dispatch({
          type: SET_MESSAGE,
          payload: error,
          kind: MESSAGE_ERROR,
        })

        dispatch({
          type: RECEIVE_INBOX_FAILED,
        })

        return Promise.reject(error)
      })
  }
}
