export const bankCodes = [
  { code: '0100', name: 'Komerční banka, a.s.' },
  { code: '0300', name: 'Československá obchodní banka, a. s.' },
  { code: '0600', name: 'MONETA Money Bank, a.s.' },
  { code: '0710', name: 'ČESKÁ NÁRODNÍ BANKA' },
  { code: '0800', name: 'Česká spořitelna, a.s.' },
  { code: '2010', name: 'Fio banka, a.s.' },
  { code: '2020', name: 'MUFG Bank (Europe) N.V. Prague Branch' },
  { code: '2030', name: 'Československé úvěrní družstvo' },
  { code: '2060', name: 'Citfin, spořitelní družstvo' },
  { code: '2070', name: 'TRINITY BANK a.s.' },
  { code: '2100', name: 'Hypoteční banka, a.s.' },
  { code: '2200', name: 'Peněžní dům, spořitelní družstvo' },
  { code: '2220', name: 'Artesa, spořitelní družstvo' },
  { code: '2240', name: 'Poštová banka, a.s., pobočka Česká republika' },
  { code: '2250', name: 'Banka CREDITAS a.s.' },
  { code: '2260', name: 'NEY spořitelní družstvo' },
  { code: '2275', name: 'Podnikatelská družstevní záložna' },
  { code: '2600', name: 'Citibank Europe plc, organizační složka' },
  { code: '2700', name: 'UniCredit Bank Czech Republic and Slovakia, a.s.' },
  { code: '3030', name: 'Air Bank a.s.' },
  { code: '3050', name: 'BNP Paribas Personal Finance SA, odštěpný závod' },
  { code: '3060', name: 'PKO BP S.A., Czech Branch' },
  { code: '3500', name: 'ING Bank N.V.' },
  { code: '4000', name: 'Expobank CZ a.s.' },
  { code: '4300', name: 'Českomoravská záruční a rozvojová banka, a.s.' },
  { code: '5500', name: 'Raiffeisenbank a.s.' },
  { code: '5800', name: 'J & T BANKA, a.s.' },
  { code: '6000', name: 'PPF banka a.s.' },
  { code: '6100', name: 'Equa bank a.s.' },
  { code: '6200', name: 'COMMERZBANK Aktiengesellschaft, pobočka Praha' },
  { code: '6210', name: 'mBank S.A., organizační složka' },
  { code: '6300', name: 'BNP Paribas S.A., pobočka Česká republika' },
  { code: '6700', name: 'Všeobecná úverová banka a.s., pobočka Praha' },
  { code: '6800', name: 'Sberbank CZ, a.s.' },
  {
    code: '7910',
    name: 'Deutsche Bank Aktiengesellschaft Filiale Prag, organizační složka',
  },
  { code: '7940', name: 'Waldviertler Sparkasse Bank AG' },
  { code: '7950', name: 'Raiffeisen stavební spořitelna a.s.' },
  { code: '7960', name: 'Českomoravská stavební spořitelna, a.s.' },
  { code: '7970', name: 'Wüstenrot - stavební spořitelna a.s.' },
  { code: '7980', name: 'Wüstenrot hypoteční banka a.s.' },
  { code: '7990', name: 'Modrá pyramida stavební spořitelna, a.s.' },
  {
    code: '8030',
    name: 'Volksbank Raiffeisenbank Nordoberpfalz eG pobočka Cheb',
  },
  { code: '8040', name: 'Oberbank AG pobočka Česká republika' },
  { code: '8060', name: 'Stavební spořitelna České spořitelny, a.s.' },
  { code: '8090', name: 'Česká exportní banka, a.s.' },
  { code: '8150', name: 'HSBC France - pobočka Praha' },
  { code: '8190', name: 'Sparkasse Oberlausitz-Niederschlesien' },
  { code: '8198', name: 'FAS finance company s.r.o.' },
  { code: '8199', name: 'MoneyPolo Europe s.r.o.' },
  {
    code: '8200',
    name:
      'PRIVAT BANK der Raiffeisenlandesbank Oberösterreich Aktiengesellschaft, pobočka Česká republika',
  },
  { code: '8215', name: 'ALTERNATIVE PAYMENT SOLUTIONS, s.r.o.' },
  { code: '8220', name: 'Payment execution s.r.o.' },
  { code: '8225', name: 'ORANGETRUST s.r.o.' },
  { code: '8230', name: 'EEPAYS s.r.o.' },
  { code: '8240', name: 'Družstevní záložna Kredit' },
  {
    code: '8250',
    name: 'Bank of China (Hungary) Close Ltd. Prague branch, odštěpný závod',
  },
  {
    code: '8255',
    name: 'Bank of Communications Co., Ltd., Prague Branch odštěpný závod',
  },
  { code: '8260', name: 'PAYMASTER a.s.' },
  {
    code: '8265',
    name:
      'Industrial and Commercial Bank of China Limited, Prague Branch, odštěpný závod',
  },
  { code: '8270', name: 'Fairplay Pay s.r.o.' },
  { code: '8280', name: 'B-Efekt a.s.' },
  { code: '8282', name: 'Beekash Payment Solutions, s.r.o.' },
  { code: '8283', name: 'Qpay s.r.o.' },
  { code: '8291', name: 'Business Credit s.r.o.' },
  { code: '8292', name: 'Money Change s.r.o.' },
  { code: '8293', name: 'Mercurius partners s.r.o.' },
  { code: '8294', name: 'GrisPayUnion s.r.o.' },
  { code: '8295', name: 'NOVARED s.r.o.' },
  { code: '8296', name: 'PaySysEU s.r.o.' },
]
