import React from 'react'

const Pagination = (props) => {
  const { current_page = 1, total_pages = 1 } = props.pagination || {}

  let pagination_pages = []

  if (total_pages < 6) {
    for (let i = 0; i < total_pages; i++) {
      let page
      if (i + 1 === current_page) {
        page = <span className="pagination__page active">{i + 1}</span>
      } else {
        page = (
          <button
            type="button"
            onClick={() => {
              props.paginate(i + 1)
            }}
            className="pagination__page"
          >
            {i + 1}
          </button>
        )
      }
      pagination_pages.push(page)
    }
  } else {
    let page, from, to

    if (current_page >= 4) {
      pagination_pages.push(
        <button
          type="button"
          onClick={() => {
            props.paginate(1)
          }}
          className="pagination__page"
        >
          1
        </button>
      )
      pagination_pages.push(<span className="pagination__space">...</span>)
    }

    if (current_page < 4) {
      from = 1
      to = 4
    } else if (current_page > total_pages - 3) {
      from = total_pages - 3
      to = total_pages
    } else {
      from = current_page - 2
      to = current_page + 2
    }

    for (let i = from; i <= to; i++) {
      if (i === current_page) {
        page = <span className="pagination__page active">{i}</span>
      } else {
        page = (
          <button
            type="button"
            onClick={() => {
              props.paginate(i)
            }}
            className="pagination__page"
          >
            {i}
          </button>
        )
      }
      pagination_pages.push(page)
    }

    if (current_page < total_pages - 2) {
      pagination_pages.push(<span className="pagination__space">...</span>)
      pagination_pages.push(
        <button
          type="button"
          onClick={() => {
            props.paginate(total_pages)
          }}
          className="pagination__page"
        >
          {total_pages}
        </button>
      )
    }
  }

  if (total_pages > 1) {
    return (
      <div className="pagination footline">
        {current_page > 1 && (
          <button
            type="button"
            onClick={() => {
              props.paginate(current_page - 1)
            }}
            className="pagination__prev"
          >
            <i className="ico ico-prev" /> Předchozí
          </button>
        )}
        {pagination_pages.map((element, index) => {
          return <span key={index}>{element}</span>
        })}
        {current_page < total_pages && (
          <button
            type="button"
            onClick={() => {
              props.paginate(current_page + 1)
            }}
            className="pagination__next"
          >
            Další <i className="ico ico-next" />
          </button>
        )}
      </div>
    )
  } else {
    return null
  }
}

export default Pagination
