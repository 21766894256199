import React from 'react'
import Loading from './loading'

const ConfirmationNote = ({ close, text, loading = false }) => {
  return (
    <React.Fragment>
      <div className="relative">
        {loading && <Loading />}

        <div dangerouslySetInnerHTML={{ __html: text }} />
        <div className="footline">
          <button
            className="btn btn--primary btn--next"
            onClick={() => close()}
          >
            DÁLE
          </button>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ConfirmationNote
