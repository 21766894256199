import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import AdminContent from '../layout/admin-content'

class NotFoundAdmin extends Component {
  render() {
    return (
      <AdminContent subtitle="Stránka nenalezena">
        <div className="row">
          <div className="col col--is--12">
            <div className="admin__inner">
              <p>
                Zpět na <Link to={'/ucet/nastenka'}>Dashboard</Link>.
              </p>
            </div>
          </div>
        </div>
      </AdminContent>
    )
  }
}

export default NotFoundAdmin
