import React, { Component } from 'react'
import { formatCurrency } from '../../utils/functions'

class Device extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value: props.value || 0,
    }
    this.inc = this.inc.bind(this)
    this.dec = this.dec.bind(this)
    this.setValue = this.setValue.bind(this)
  }

  componentDidMount() {
    if (
      !(this.props.value > 0) &&
      this.props.device &&
      this.props.device.defaultCount
    ) {
      // TODO: nenastavovat default value pokud je uz zvolene jine zarizeni
      this.setValue(this.props.device.defaultCount)
      for (let i = 0; i < this.props.device.defaultCount; i++) {
        this.props.inc()
      }
    }
  }

  setValue(val) {
    this.props.change(
      this.props.isUpsell
        ? `device[${this.props.device.group}]`
        : `device[${this.props.selectedPortfolio}][${this.props.device.group}]`,
      val
    )
    this.setState({
      value: val,
    })
    // this.props.resetDeviceTotal(val)
  }

  inc() {
    if (this.props.deviceTotal < this.props.deviceMax) {
      this.setValue(this.state.value + 1)
      this.props.inc()
      // this.props.handleChange(this.props.device.group, this.state.value + 1)
    }
  }

  dec() {
    if (
      this.props.deviceTotal >= this.props.deviceMin &&
      this.state.value > 0
    ) {
      this.setValue(this.state.value - 1)
      this.props.dec()
      // this.props.handleChange(this.props.device.group, this.state.value - 1)
    }
  }

  render() {
    const { device, toggleModal } = this.props

    return (
      <React.Fragment>
        <div className={'portfolio__content__item'}>
          <div className={'portfolio__content__item__name'}>
            {device.name} {device.required && '*'}
            <span
              onClick={toggleModal}
              className={'portfolio__content__item__info'}
            >
              ?
            </span>
          </div>
          <div className={'portfolio__content__item__inner'}>
            <div className={'quantity-picker'}>
              <button
                type="button"
                disabled={device.disabled}
                onClick={this.dec}
                className={
                  'quantity-picker__button quantity-picker__button--dec'
                }
              >
                -
              </button>
              <input
                type="text"
                disabled={device.disabled}
                value={`${this.state.value} ks`}
                className={'quantity-picker__input'}
                readOnly
              />
              <button
                type="button"
                disabled={device.disabled}
                onClick={this.inc}
                className={
                  'quantity-picker__button quantity-picker__button--inc'
                }
              >
                +
              </button>
            </div>
            <div className={'portfolio__content__item__price'}>
              +&nbsp;
              {formatCurrency(
                this.state.value > 0 ? device.priceVat * this.state.value : 0,
                0
              )}{' '}
              {device.discountVat > 0 && (
                <div>{formatCurrency(device.discountVat, 0)}</div>
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default Device
