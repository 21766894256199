import React, { Component } from 'react'
import { connect } from 'react-redux'

import { compose } from 'redux'

import Loading from '../component/loading'
import ProfileSettingPasswordlForm from '../form/profile-setting-password'

import Switch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'

import AdminContent from '../layout/admin-content'
import ContractHoc from '../hoc/contract-hoc'
import { modalStyle } from '../utils/modal-style'

import { requestChangePassword, requestToggleLiveTV } from '../actions/profile'
import {
  getCustomerAcknowledgment,
  changeCustomerAcknowledgment,
} from '../actions/acknowledgments'
import { requestPin } from '../actions/app'

import PinConfirmation from '../component/pin-confirmation'
import ConfirmationNote from '../component/confirmation-note'
import DigiModalContext from '../context/digi-modal-context'

const DigiSwitch = withStyles({
  /*
    switchBase: {
      color: "#efefef",
      '&$checked': {
        color: "#1e1ec8",
      },
      '&$checked + $track': {
        backgroundColor: "#9f9f9f",
      },
    },
    checked: {},
    track: {},
    */
})(Switch)

class ProfileSetup extends Component {
  static contextType = DigiModalContext

  constructor(props) {
    super(props)
    this.state = {
      editForm: null,
      passwordLoading: false,
      loading: false,
      checked: {},
    }
    this.openEditForm = this.openEditForm.bind(this)
    this.closeEditForm = this.closeEditForm.bind(this)
    this.changePassword = this.changePassword.bind(this)
    this.handleAcknowledgmentChange = this.handleAcknowledgmentChange.bind(this)
  }

  componentDidMount() {
    this.setState({
      loading: true,
    })
    this.props
      .getCustomerAcknowledgment()
      .then(() => {
        this.setState({
          loading: false,
        })
      })
      .catch((err) => {
        console.log(err)
        this.setState({
          loading: false,
        })
      })
  }

  openEditForm(element) {
    this.setState({
      editForm: element,
    })
  }

  closeEditForm() {
    this.setState({
      editForm: null,
    })
  }

  changePassword(values) {
    this.setState({
      passwordLoading: true,
    })
    this.props
      .requestChangePassword(values)
      .then(() => {
        this.setState({
          passwordLoading: false,
        })

        this.context.toggleDigiModal(
          <ConfirmationNote
            text={
              this.props.content[
                'content.profile.personal.data.change.common.confirm'
              ]
            }
            close={this.context.toggleDigiModal}
          />
        )
      })
      .catch((err) => {
        this.setState({
          passwordLoading: false,
        })
        return Promise.resolve(err)
      })
  }

  handleAcknowledgmentChange(ack_id, checked, pin) {
    // console.log(ack_id)
    // console.log(event.target.checked)
    this.setState({ loading: true })
    this.props.changeCustomerAcknowledgment(ack_id, checked, pin).then(() => {
      this.setState({ loading: false })
    })
  }

  render() {
    const { content, help, acknowledgments } = this.props

    // const styles = {
    //   fontFamily: '"Montserrat", sans-serif',
    // }

    return (
      <DigiModalContext.Consumer>
        {({ setStyle, toggleDigiModal }) => (
          <AdminContent
            advertisementTarget="profile.settings.banner"
            title="Můj profil"
            subtitle={content['content.profile.settings.title']}
            help={help['help.profile.settings.help']}
            link="/ucet/profil"
          >
            <div className="row">
              <div className="col col--is--12 col--xl--12">
                <div className="admin__inner">
                  <div className="section">
                    <h2>Přihlašovací údaje</h2>
                    <div className="row">
                      <div className="col col--is--12 col--lg--6">
                        <ul className="list list--6--2 list--action align-center">
                          <li className="list__row">
                            <div className="list__label list__col--auto list__col">
                              Přihlašovací e-mail
                            </div>
                            <div className="list__value list__col">
                              {this.props.user.email}
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="section">
                    <h2>Změna hesla</h2>
                    <div className="row">
                      <div className="col col--is--12 col--lg--6">
                        <p>
                          Heslo musí splňovat tyto požadavky: 8 znaků, velká a
                          malá písmena a alespoň jeden speciální znak.
                        </p>
                        <div className="relative">
                          {this.state.passwordLoading && <Loading />}

                          <ProfileSettingPasswordlForm
                            onSubmit={this.changePassword}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  {this.props.contract && this.props.contract.live_tv_status && (
                    <div className="section">
                      <h2>Nastavení služeb</h2>
                      <div className="row">
                        <div className="col col--is--12 col--lg--6">
                          <FormControlLabel
                            control={
                              <Switch
                                checked={this.props.contract.live_tv}
                                onChange={(e) => {
                                  const lTV = e.target.checked
                                  this.setState({ loading: true })
                                  this.props
                                    .requestPin()
                                    .then(() => {
                                      toggleDigiModal() // zavru
                                      setStyle(modalStyle)
                                      toggleDigiModal(
                                        <PinConfirmation
                                          text={''}
                                          confirmation={(validationPin) =>
                                            this.props
                                              .requestToggleLiveTV(
                                                this.props.contract_id,
                                                {
                                                  validationPin,
                                                  liveTv: lTV,
                                                }
                                              )
                                              .then(() => {
                                                toggleDigiModal()
                                              })
                                          }
                                        />
                                      )
                                    })
                                    .then(() => {
                                      this.setState({
                                        loading: false,
                                      })
                                    })
                                }}
                                value="checkedA"
                                color="primary"
                                //style={toggleStyles}
                                //inputProps={{ 'aria-label': 'secondary checkbox' }}
                              />
                            }
                            //label="Povolit automatické přihlášení do portálu moje.telly.cz"
                            label={
                              <Typography>
                                Povolit automatické přihlášení do portálu
                                moje.telly.cz
                              </Typography>
                            }
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="section">
                    <h2>Zpracování osobních údajů</h2>
                    <div className="row">
                      <div className="col col--is--12 col--lg--6">
                        <ul className="list list--6--2 list--action align-center">
                          <li className="list__row">
                            <div className="list__col">
                              {this.state.loading && <Loading />}
                            </div>
                          </li>
                          {acknowledgments &&
                            Object.entries(acknowledgments.items).map(
                              (ack, i) => (
                                <li key={`ack_${i}`} className="list__row">
                                  <div className="list__col">
                                    <FormControlLabel
                                      control={
                                        <DigiSwitch
                                          checked={ack[1].confirmed}
                                          onChange={(e) => {
                                            const checked = e.target.checked
                                            this.setState({ loading: true })
                                            this.props
                                              .requestPin()
                                              .then(() => {
                                                toggleDigiModal() // zavru
                                                setStyle(modalStyle)
                                                toggleDigiModal(
                                                  <PinConfirmation
                                                    text={''}
                                                    confirmation={(
                                                      validationPin
                                                    ) =>
                                                      this.props
                                                        .changeCustomerAcknowledgment(
                                                          ack[0],
                                                          checked,
                                                          validationPin
                                                        )
                                                        .then(() => {
                                                          toggleDigiModal()
                                                        })
                                                        .catch(() => {})
                                                    }
                                                  />
                                                )
                                              })
                                              .then(() => {
                                                this.setState({
                                                  loading: false,
                                                })
                                              })
                                          }}
                                          value="checkedA"
                                          color="primary"
                                          //inputProps={{ 'aria-label': 'secondary checkbox' }}
                                        />
                                      }
                                      label={
                                        <Typography>
                                          {ack[1].content}
                                        </Typography>
                                      }
                                    />
                                  </div>
                                </li>
                              )
                            )}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </AdminContent>
        )}
      </DigiModalContext.Consumer>
    )
  }
}

function mapStateToProps(state) {
  return {
    help: state.translation.help,
    content: state.translation.content,
    user: state.user,
    acknowledgments: state.acknowledgments,
  }
}

const enhance = compose(
  connect(mapStateToProps, {
    requestChangePassword,
    getCustomerAcknowledgment,
    changeCustomerAcknowledgment,
    requestToggleLiveTV,
    requestPin,
  }),
  ContractHoc
)

export default enhance(ProfileSetup)
