import React from 'react'
import { formatCurrency } from '../utils/functions'

export default function LoyaltySummary(props) {
  const { data } = props

  return (
    <>
      <table className="order__total order__total__loyalty">
        <thead>
          <tr>
            <th>
              <h2 className={'order__total__title'}>Položka</h2>
            </th>
            <th>Cena za měsíc</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, i) => (
            <tr key={i} className={'order__total__row'}>
              <td
                className={
                  'order__total__row__name order__total__row__name--big'
                }
              >
                {item.title}
              </td>
              <td
                className={
                  'order__total__row__price order__total__row__price--big'
                }
              >
                {formatCurrency(item.newPrice, 0)}
              </td>
            </tr>
          ))}

          {/* {1 > 0 && (
            <React.Fragment>
              <tr className={'order__total__row order__total__row--gray'}>
                <td
                  className={
                    'order__total__row__name order__total__row__name--final'
                  }
                >
                  nyní uhradíte{' '}
                  <span onClick={() => {}}>(Proč tato částka?)</span>
                </td>
                <td
                  className={
                    'order__total__row__price order__total__row__price--final'
                  }
                >
                </td>
              </tr>
              <tr className={'order__total__row order__total__row--gray'}>
                <td colSpan="2" className={'order__total__text'}>
                  <div className="order__total__text__inner" />
                </td>
              </tr>
            </React.Fragment>
          )} */}
        </tbody>
      </table>
    </>
  )
}
