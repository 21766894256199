import React, { Component } from 'react'

import { formatCurrency } from '../../utils/functions'

class PackageOther extends Component {
  render() {
    const { item, children, modalContent, toggleModal } = this.props

    return (
      <div className={'portfolio__content__item'}>
        <label className={'checkbox'}>
          {children}
          <span className={'portfolio__content__item__name'}>{item.name}</span>
          {modalContent && (
            <span
              className="portfolio__content__item__info"
              onClick={(e) => {
                e.preventDefault()
                toggleModal(modalContent)
              }}
            >
              ?
            </span>
          )}
        </label>
        <div className={'portfolio__content__item__price'}>
          +&nbsp;
          {formatCurrency(item.priceOriginalVat, 0)}
        </div>
        {item.discountVat > 0 && <div>{formatCurrency(item.priceVat, 0)}</div>}
        {/* <button className="btn btn--primary" onClick={selectPackage}>
          Vybrat
        </button> */}
      </div>
    )
  }
}

export default PackageOther
