import React, { Component } from 'react'
import { connect } from 'react-redux'

import Modal from 'react-modal'
import { modalStyleTiny } from '../utils/modal-style'

// import { createPayment } from '../actions/invoices'

import { reinitSmartcard } from '../actions/services'

import Loading from '../component/loading'
import ModalLogo from '../component/modal-logo'

class BtnCardReactivation extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modal: false,
    }
    this.reinitSmartcard = this.reinitSmartcard.bind(this)
    this.confirmReinitSmartcard = this.confirmReinitSmartcard.bind(this)
    this.closeConfirmation = this.closeConfirmation.bind(this)
  }

  confirmReinitSmartcard(smart_id) {
    this.setState({
      modal: true,
      loadingReinit: false,
    })
  }

  closeConfirmation() {
    this.setState({
      modal: false,
    })
  }

  reinitSmartcard() {
    this.setState({
      loadingReinit: true,
    })

    this.props
      .reinitSmartcard(this.props.contract_id, this.props.card.id)
      .then((response) => {
        this.setState({
          loadingReinit: false,
          modal: false,
        })
      })
      .catch((error) => {
        this.setState({
          loadingReinit: false,
        })
      })
  }

  render() {
    return (
      <React.Fragment>
        <Modal
          appElement={document.getElementById('root')}
          style={{
            ...modalStyleTiny,
            content: {
              ...modalStyleTiny['content'],
              maxWidth: '500px',
              textAlign: 'center',
            },
          }}
          isOpen={this.state.modal}
        >
          {this.state.loadingReinit && <Loading />}

          <ModalLogo />

          <button className="button-close" onClick={this.closeConfirmation}>
            zavřít
          </button>

          <div className="intro__text">
            <div className="row">
              <div className="col">
                <p>
                  Potvzení reaktivace karty číslo{' '}
                  <strong>{this.props.card.serial}</strong>
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col text--center">
                {/*<button onClick={this.closeConfirmation} className="btn btn--secondary btn--prev">Ne</button>{' '}*/}
                <button
                  onClick={this.reinitSmartcard}
                  className="btn btn--primary btn--next"
                >
                  Reaktivovat
                </button>
              </div>
            </div>
          </div>
        </Modal>
        <button
          className={this.props.className}
          onClick={this.confirmReinitSmartcard}
        >
          Reaktivace karty
        </button>
      </React.Fragment>
    )
  }
}

export default connect(null, {
  reinitSmartcard,
})(BtnCardReactivation)
