import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import sanitizeHtml from 'sanitize-html'
import { ALLOWED_TAGS, ALLOWED_ATTRIBUTES } from '../conf/constants'

import { requestLogout } from '../actions/login'

import tellyLogo from '../assets/images/logo.svg'

const PublicLayout = ({ content, auth, requestLogout, children }) => {
  const [logouting, setLogouting] = useState(false)

  return (
    <div className="intro">
      <div className="intro__inner">
        <div className="intro__inner__logo">
          <img className="logo" src={tellyLogo} alt="" />
        </div>
        <div className="intro__inner__text">
          <div
            className="intro__inner__text__title"
            dangerouslySetInnerHTML={{
              __html:
                content &&
                sanitizeHtml(content['content.homepage.bigtext.title'], {
                  allowedTags: ALLOWED_TAGS,
                  allowedAttributes: ALLOWED_ATTRIBUTES,
                }),
            }}
          />
          <div
            className="intro__inner__text__subtitle"
            dangerouslySetInnerHTML={{
              __html:
                content &&
                sanitizeHtml(content['content.homepage.bigtext.subtitle'], {
                  allowedTags: ALLOWED_TAGS,
                  allowedAttributes: ALLOWED_ATTRIBUTES,
                }),
            }}
          />
        </div>
        <div className="intro__box">
          <div className="intro__links__call-me">
            <Link to="/zavolejte-mi">Nevím si rady, zavolejte mi</Link>
          </div>
          {children}
          <div className="intro__links__web">
            <a href="https://telly.cz/" target="__blank">
              Přejít na web telly.cz
            </a>
            {auth && (
              <button
                style={{ marginLeft: '24px' }}
                onClick={() => {
                  setLogouting(true)
                  requestLogout()
                }}
                className="btn btn--primary"
                type="button"
                disabled={logouting}
              >
                Odhlásit
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

function mapStateToProps(state) {
  return {
    content: state.translation.content,
    auth: state.user.auth,
  }
}

export default connect(mapStateToProps, { requestLogout })(PublicLayout)
