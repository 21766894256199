import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'

import { renderTooglePassword } from './../utils/form'

class ConfirmPasswordForm extends Component {
  render() {
    const { handleSubmit, confirmText, close } = this.props
    return (
      <form onSubmit={handleSubmit}>
        <div className={'modal__title'}>Potvrzení</div>
        <label htmlFor="password" className={'modal__subtitle'}>
          Pro potvrzení zopakujte heslo, které jste zadali při registraci
        </label>
        <div className={'form__row'}>
          <Field
            id="password"
            name="password"
            type="password"
            component={renderTooglePassword}
          />
        </div>
        <div className={'modal__actions'}>
          <button type="button" className="btn btn--secondary" onClick={close}>
            Zpět
          </button>
          <button type="submit" className="btn btn--primary">
            {confirmText}
          </button>
        </div>
      </form>
    )
  }
}

function validate(values) {
  const errors = {}

  if (!values.password) {
    errors.password = 'Prosím, vyplňte heslo.'
  }

  return errors
}

export default reduxForm({
  form: 'confirm_password',
  // touchOnBlur: false,
  validate,
})(ConfirmPasswordForm)
