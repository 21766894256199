import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import ConfirmPasswordForm from '../form/confirm-password-form'
import DigiModalContext from '../context/digi-modal-context'

import { formatCurrency, downloadFile } from '../utils/functions'

import { renderCheckbox, required } from '../utils/form'
import AckSC from '../component/AckSC'

import sanitizeHtml from 'sanitize-html'

class ServicesActivationStep3Upsell extends Component {
  static contextType = DigiModalContext

  constructor(props) {
    super(props)

    this.confirmSubmit = this.confirmSubmit.bind(this)
  }

  confirmSubmit() {
    this.context.toggleDigiModal(
      <ConfirmPasswordForm
        onSubmit={(values) => {
          this.context.toggleDigiModal()
          return this.props.onSubmit(values, this.props.priceVat > 0)
        }}
        confirmText="Objednat"
        close={() => this.context.toggleDigiModal()}
      />
    )
  }

  render() {
    const {
      contractPreview,
      acknowledgments,
      priceVat,
      goToStep,
      handleSubmit,
      content,
      // portfolioKey,
    } = this.props

    return (
      <form
        onSubmit={handleSubmit(this.confirmSubmit)}
        className="order__agreement"
      >
        <div className="activation activation--3">
          <h2 className={'h2'}>Návrh dodatku</h2>
          <p>
            {sanitizeHtml(
              content[`content.upsell.contract`],
              {
                allowedTags: [],
                allowedAttributes: [],
              }
            )}
          </p>
          <div
            className="contract no__mobile"
            dangerouslySetInnerHTML={
              contractPreview && {
                __html: `<iframe style="border:0; width: 100%;" height="700px" src="data:${contractPreview.contentType};base64,${contractPreview.base64}"></iframe>`,
              }
            }
          />
          <button
            type="button"
            className="btn btn--primary no__desktop"
            onClick={() => downloadFile(contractPreview)}
          >
            PDF smlouva
          </button>
          <div className="form__row form__row--register form__row--gdpr">
            <div className="form__item__group form__item__group--checkbox">
              {acknowledgments &&
                Object.values(acknowledgments).map((ack) => {
                  return (
                    <div className="form__item" key={ack.id}>
                      <AckSC ack={ack}>
                        <Field
                          id={`acknowledgments[${ack.id}]`}
                          name={`acknowledgments[${ack.id}]`}
                          component={renderCheckbox}
                          className={'acknowledgment'}
                          type="checkbox"
                          placeholder=""
                          validate={ack.required ? required : null}
                          disabled={ack.selected && ack.required}
                        />
                      </AckSC>
                    </div>
                  )
                })}
            </div>
          </div>
          <div className="footline footline--activation footline footline--nextprev">
            <button
              className="btn btn--secondary"
              type="button"
              onClick={() => {
                goToStep(2)
              }}
            >
              Zpět
            </button>
            <button className="btn btn--primary" type="submit">
              Objednat{' '}
              {priceVat > 0 && `a zaplatit ${formatCurrency(priceVat)}`}
            </button>
          </div>
        </div>
      </form>
    )
  }
}

ServicesActivationStep3Upsell = reduxForm({
  form: 'service_upsell_contract_form_u',
  touchOnBlur: false,
})(ServicesActivationStep3Upsell)

function mapStateToProps(state) {
  return {
    loading: state.contract_upsell.loading,
    contractPreview: state.contract_upsell.overview.customer.contractPreview,
    acknowledgments: state.contract_upsell.overview.customer.acknowledgments,
    priceVat: state.contract_upsell.overview.priceVat,
    content: state.translation.content,
  }
}

export default connect(mapStateToProps, {})(ServicesActivationStep3Upsell)
