import React, { Component } from 'react'
import { connect } from 'react-redux'

import { createPayment, validatePayment } from '../actions/invoices'

import Loading from '../component/loading'

class GoPayPaymentButton extends Component {
  constructor(props) {
    super(props)
    this.state = {
      lastInvoiceModal: false,
    }
    this.createPayment = this.createPayment.bind(this)
    this.toggleLastInvoiceModal = this.toggleLastInvoiceModal.bind(this)
  }

  toggleLastInvoiceModal() {
    this.setState({
      lastInvoiceModal: !this.state.lastInvoiceModal,
    })
  }

  createPayment(invoice_id) {
    this.setState({
      loading: true,
    })
    this.props
      .createPayment(this.props.contract_id, invoice_id)
      .then((data) => {
        if (data.gate_url) {
          window._gopay.checkout(
            {
              gatewayUrl: data.gate_url,
              inline: true,
            },
            function (ret) {
              if (!ret.id) {
                // TODO: error
              }
              this.props
                .validatePayment(data.midas_id)
                .then((vdata) => {
                  console.log(vdata)
                  this.setState({
                    loading: false,
                  })
                })
                .catch((err) => {
                  console.error(err)
                  this.setState({
                    loading: false,
                  })
                })
            }.bind(this)
          )
        }
      })
      .catch((error) => {
        this.setState({
          loading: false,
        })
      })
  }

  handleCreatePaymentClick(invoice_id, all = false) {
    if (
      'string' === typeof this.props.invoice_id &&
      this.props.invoice_id !== this.props.last_invoice_id
    ) {
      this.toggleLastInvoiceModal()
      //this.createPayment(invoice_id)
    } else {
      this.createPayment(invoice_id)
    }
  }

  render() {
    return (
      <button
        className={this.props.className}
        type="button"
        onClick={() =>
          this.handleCreatePaymentClick(
            this.props.invoice_id,
            this.props.last_invoice_id
          )
        }
      >
        {this.state.loading && (
          <Loading
            className="global-loading"
            text="Probíhá přesměrování na platební bránu"
          />
        )}
        {this.props.label}
      </button>
    )
  }
}

export default connect(null, {
  createPayment,
  validatePayment,
})(GoPayPaymentButton)
