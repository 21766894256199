import { submit } from 'redux-form'
// import { toast } from "react-toastify";

import {
  // REQUEST_USER_INFO,
  // RECEIVE_USER_INFO,
  // RECEIVE_USER_INFO_FAILED,
  // REQUEST_PORTFOLIO,
  // RECEIVE_PORTFOLIO,
  // RECEIVE_PORTFOLIO_FAILED,
  REQUEST_CONTRACT_PACKAGES,
  // RECEIVE_PORTFOLIO_PACKAGES,
  // RECEIVE_PORTFOLIO_DEVICES,
  REQUEST_CONTRACT,
  RECEIVE_CONTRACT,
  RECEIVE_CONTRACT_PACKAGES,
  REQUEST_CONTRACT_DEVICES,
  RECEIVE_CONTRACT_DEVICES,
  // RECEIVE_CONTRACT_DEVICES_FAILED,
  //REQUEST_CONTRACT_INVOICES,
  //RECEIVE_CONTRACT_INVOICES,
  //RECEIVE_CONTRACT_INVOICES_FAILED,
  REQUEST_CONTRACT_INFO,
  RECEIVE_CONTRACT_INFO,
  // RECEIVE_CONTRACT_INFO_FAILED,
  SET_MESSAGE,
  SET_CURRENT_CONTRACT,
  // REQUEST_CONTACT_TYPES,
  RECEIVE_CONTACT_TYPES,
  RECEIVE_CONTACT_TYPES_FAILED,
  // START_LOADING,
  // STOP_LOADING,
  LEAVING_MODAL,
  CAN_LEAVE,
} from './types'

import { MESSAGE_ERROR, MESSAGE_SUCCESS } from '../conf/constants'

import { instance } from '../conf/axios'

export function remoteSubmitPaymentSetup() {
  return function (dispatch, getState) {
    dispatch(submit('form_payment_type'))
  }
}

export function setLeavingModal(state) {
  return function (dispatch, getState) {
    dispatch({
      type: LEAVING_MODAL,
      payload: state,
    })
  }
}

export function setCanLeave(state) {
  return function (dispatch, getState) {
    dispatch({
      type: CAN_LEAVE,
      payload: state,
    })
  }
}

export function setCurrentContractNr(contract_number) {
  return function (dispatch, getState) {
    dispatch({
      type: SET_CURRENT_CONTRACT,
      payload: contract_number,
    })
  }
}

export function getContractLiveTV(contract_id) {
  return function (dispatch, getState) {
    return instance
      .get(`customer/contract/${contract_id}/livetv`)
      .then((response) => {
        return Promise.resolve(response.data)
      })
      .catch((error) => {
        dispatch({
          type: SET_MESSAGE,
          payload: error,
          kind: MESSAGE_ERROR,
        })
        return Promise.reject(error)
      })
  }
}

export function getContactTypes() {
  return function (dispatch, getState) {
    return instance
      .get(`customer/contact-type`)
      .then((response) => {
        dispatch({
          type: RECEIVE_CONTACT_TYPES,
          payload: response.data,
        })
        return Promise.resolve(response.data)
      })
      .catch((error) => {
        dispatch({
          type: RECEIVE_CONTACT_TYPES_FAILED,
        })
        dispatch({
          type: SET_MESSAGE,
          payload: error,
          kind: MESSAGE_ERROR,
        })
        return Promise.reject(error)
      })
  }
}

export function getContractDevices(contract_id) {
  return function (dispatch, getState) {
    dispatch({
      type: REQUEST_CONTRACT_DEVICES,
      contract_id: contract_id,
    })
    return instance
      .get(`customer/contract/${contract_id}/device`)
      .then((response) => {
        dispatch({
          type: RECEIVE_CONTRACT_DEVICES,
          payload: response.data,
          contract_id: contract_id,
        })
        return Promise.resolve(response.data)
      })
      .catch((error) => {
        dispatch({
          type: SET_MESSAGE,
          payload: error,
          kind: MESSAGE_ERROR,
        })
        return Promise.reject(error)
      })
  }
}

export function getContractPackages(contract_id) {
  return function (dispatch, getState) {
    dispatch({
      type: REQUEST_CONTRACT_PACKAGES,
      contract_id: contract_id,
    })
    return instance
      .get(`customer/contract/${contract_id}/package`)
      .then((response) => {
        dispatch({
          type: RECEIVE_CONTRACT_PACKAGES,
          payload: response.data,
          contract_id: contract_id,
        })
        return Promise.resolve(response.data)
      })
      .catch((error) => {
        dispatch({
          type: SET_MESSAGE,
          payload: error,
          kind: MESSAGE_ERROR,
        })
        return Promise.reject(error)
      })
  }
}

export function getContract() {
  return function (dispatch, getState) {
    const { selected } = getState().contract_nr
    // if (ids.length > 0 && selected) {
    //   return Promise.resolve()
    // }
    dispatch({
      type: REQUEST_CONTRACT,
    })
    return instance
      .get(`customer/contract`)
      .then((response) => {
        dispatch({
          type: RECEIVE_CONTRACT,
          payload: response.data.data,
        })
        if (!selected && response.data.data.length > 0) {
          dispatch({
            type: SET_CURRENT_CONTRACT,
            payload: response.data.data[0].number,
          })
        }
        return Promise.resolve(response.data)
      })
      .catch((error) => {
        dispatch({
          type: SET_MESSAGE,
          payload: error,
          kind: MESSAGE_ERROR,
        })
        return Promise.reject(error)
      })
  }
}

export function getContractInfo(contract_id) {
  return async function (dispatch, getState) {
    dispatch({
      type: REQUEST_CONTRACT_INFO,
      contract_id: contract_id,
      loading: true,
    })
    try {
      const response = await instance.get(`customer/contract/${contract_id}`)

      dispatch({
        type: RECEIVE_CONTRACT_INFO,
        payload: response.data.data,
        contract_id: contract_id,
        loading: false,
      })

      return Promise.resolve(response.data.data)
    } catch (error) {
      dispatch({
        type: SET_MESSAGE,
        payload: error,
        kind: MESSAGE_ERROR,
      })
      return Promise.reject(error)
    }
  }
}

export function requestCall(values) {
  return function (dispatch, getState) {
    return instance
      .post(`customer/call-request`, values, { public: true })
      .then(() => {
        dispatch({
          type: SET_MESSAGE,
          payload:
            'Váš požadavek byl úspěšně přijat. V brzké době vás bude kontaktovat náš operátor.',
          kind: MESSAGE_SUCCESS,
        })
      })
      .catch((error) => {
        dispatch({
          type: SET_MESSAGE,
          payload: error,
          kind: MESSAGE_ERROR,
        })
        return Promise.reject(error)
      })
  }
}
