import React from 'react'
import { Link } from 'react-router-dom'
import { formatCurrency } from '../../utils/functions'
import GoPayPaymentButton from '../../component/gopay-payment-button'

const Invoices = ({
  invoices = {
    meta: {},
  },
  contract_id,
}) => {
  return (
    <div className="admin__inner clearfix">
      <h2>Fakturace</h2>
      <div className="row row--is--center">
        <div className="col col--is--12 col--xs--9">
          <p className="rm--margin">Celková dlužná částka k úhradě</p>
        </div>
        <div className="col col--is--12 col--xs--3 text--right">
          <strong>{formatCurrency(invoices.meta.debt)}</strong>
        </div>
      </div>
      <hr />
      <div className="row row--is--center">
        <div className="col col--is--12 col--ts--5">
          <Link className="a--arrow--green" to={'/ucet/fakturace-a-platby'}>
            Přehled fakturace
          </Link>
        </div>
        <div className="col col--is--12 col--ts--7 text--right">
          {invoices.meta.debt > 0 && (
            <GoPayPaymentButton
              className={`btn btn--primary btn--next btn--block--xs`}
              contract_id={contract_id}
              label={`Zaplatit dlužnou částku`}
              invoice_id={invoices.meta.unpaidInvoices}
              last_invoice_id={invoices.meta.lastUnpaidInvoice.id}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default Invoices
