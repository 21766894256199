import { RECEIVE_TRANSLATION } from '../actions/types'

const INITIAL_STATE = {
  help: {},
  content: {},
}

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case RECEIVE_TRANSLATION: {
      return {
        ...state,
        help: action.payload.help,
        content: action.payload.content,
      }
    }
    // no default
  }
  return state
}
