import React, { Component } from 'react'
import { connect } from 'react-redux'

import sanitizeHtml from 'sanitize-html'
import { ALLOWED_ATTRIBUTES, ALLOWED_TAGS } from '../../conf/constants'

import Device from './device'
import {
  incDeviceTotal,
  decDeviceTotal,
  resetDeviceTotal,
} from '../../actions/services'

class DevicesWrapper extends Component {
  // constructor(props) {
  //   super(props)
  //   this.state = {
  //     total:
  //       props.values && Object.values(props.values).length > 0
  //         ? Object.values(props.values).reduce((a, b) => a + b)
  //         : 0,
  //     devices: {},
  //   }

  //   this.inc = this.inc.bind(this)
  //   this.dec = this.dec.bind(this)
  //   this.handleChange = this.handleChange.bind(this)
  // }

  // inc() {
  //   if (this.props.deviceTotal < this.props.deviceMax) {
  //     this.props.incDeviceTotal()
  //     return true
  //   }
  //   return false
  // }

  // dec() {
  //   if (this.props.deviceTotal > 0) {
  //     this.props.decDeviceTotal()
  //     return true
  //   }
  //   return false
  // }

  // handleChange(device, qty) {
  //   this.setState({
  //     devices: {
  //       ...this.state.devices,
  //       [device]: qty,
  //     },
  //   })
  // }

  render() {
    const {
      devices,
      toggleModal,
      content,
      portfolioKey,
      change,
      // values,
      deviceMax,
      deviceMin,
      deviceTotal,
      incDeviceTotal,
      decDeviceTotal,
      resetDeviceTotal,
      selectedPortfolio,
      isUpsell,
    } = this.props

    return (
      <React.Fragment>
        {devices.map((item) => (
          <Device
            key={`${selectedPortfolio}_${item.group}`}
            device={item}
            inc={incDeviceTotal}
            dec={decDeviceTotal}
            resetDeviceTotal={resetDeviceTotal}
            deviceTotal={deviceTotal}
            deviceMax={deviceMax}
            deviceMin={deviceMin}
            // handleChange={this.handleChange}
            toggleModal={() =>
              toggleModal(
                sanitizeHtml(
                  content[
                    `content.new.contract.${portfolioKey}.${item.group}.description`
                  ],
                  {
                    allowedTags: ALLOWED_TAGS,
                    allowedAttributes: ALLOWED_ATTRIBUTES,
                  }
                )
              )
            }
            change={change}
            selectedPortfolio={selectedPortfolio}
            value={this.props.values && this.props.values[item.group]}
            isUpsell={isUpsell}
          />
        ))}
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
    deviceTotal: state.contract_new.deviceTotal,
    selectedPortfolio: state.contract_new.selectedPortfolio,
  }
}

export default connect(mapStateToProps, {
  incDeviceTotal,
  decDeviceTotal,
  resetDeviceTotal,
})(DevicesWrapper)

// export default DevicesWrapper
