import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose } from 'redux'
//import {Link} from 'react-router-dom'
//import AdminHeader from "../component/admin-header"
import LiveTvButton from '../component/live-tv'
import ContractHoc from '../hoc/contract-hoc'
// import WithLoader from '../hoc/with-loader'

import { requestCustomerInbox } from '../actions/inbox'
import { getContractInvoices } from '../actions/invoices'
import {
  getContractInfo,
  getContractPackages,
  getContractDevices,
} from '../actions/admin'

import { getAdvertisement, startLoading, stopLoading } from '../actions/app'

import News from '../component/dashboard/news'
import Invoices from '../component/dashboard/invoices'
import Services from '../component/dashboard/services'

import Banner from '../component/banner'
//import Banner from '../component/banner';

import AdminContent from '../layout/admin-content'

class Dashboard extends Component {
  componentDidMount() {
    if (this.props.contract_id) {
      this.props.startLoading()
      Promise.all([
        //this.props.requestCustomerInbox().catch( err => { console.log(err) }),
        this.props.getContractInvoices(this.props.contract_id).catch((err) => {
          console.log(err)
        }),
        this.props.getContractPackages(this.props.contract_id).catch((err) => {
          console.log(err)
        }),
        this.props.getContractInfo(this.props.contract_id).catch((err) => {
          console.log(err)
        }),
        this.props.getContractDevices(this.props.contract_id).catch((err) => {
          console.log(err)
        }),
      ])
        .then(() => {
          this.props.stopLoading()
        })
        .catch((err) => {
          console.log(err)
          this.props.stopLoading()
        })
    }

    var script = document.createElement('script')
    script.src = 'https://gw.sandbox.gopay.com/gp-gw/js/embed.js'
    document.getElementsByTagName('head')[0].appendChild(script)
    if (process.env.REACT_APP_USE_CHAT === 'true') {
      var scriptChat = document.createElement('script')
      scriptChat.src = 'https://chat.telly.cz/telly/scripts/webchat.js'
      document.getElementsByTagName('head')[0].appendChild(scriptChat)
    }
  }

  render() {
    const {
      contract_id,
      invoices = {},
      inbox = {},
      packages = {},
      devices = {},
      content,
      help,
    } = this.props

    const allPackages = packages[contract_id]
      ? [
          ...packages[contract_id].core,
          ...packages[contract_id].suppl,
          ...packages[contract_id].other,
        ].filter((p) => !p.private)
      : []

    const contract = this.props.contracts[this.props.contract_id] || {}

    let loyaltyAvailable = false
    try {
      loyaltyAvailable = contract.pricelist.includes('TELLY')
    } catch (error) {
      loyaltyAvailable = false
    }

    return (
      <AdminContent
        //advertisementTarget='account.dashboard'
        subtitle={content['content.account.dashboard.title']}
        help={help['help.account.dashboard.help']}
      >
        <div className="row">
          <div className="col col--is--12 col--xl--6 col--big">
            <News inbox={inbox} />
            <Invoices
              invoices={invoices[contract_id]}
              contract_id={contract_id}
            />

            <div className="row">
              {loyaltyAvailable && (
                <div className="col col--is--12">
                  <Link to="/ucet/vernostni-program/prehled">
                    <button className="admin__inner loyalty__banner">
                      <h2>
                        Získejte vyladěné odměny
                        <br />v Telly klubu
                      </h2>
                      <p>Přejít do Telly klubu</p>
                    </button>
                  </Link>
                </div>
              )}
              {contract.live_tv && contract.live_tv_status && (
                <div className="col col--is--12">
                  <LiveTvButton
                    className="admin__inner tv"
                    contract_id={contract.id}
                  >
                    <h2>Online TV</h2>
                    <p>Sledovat TV</p>
                  </LiveTvButton>
                </div>
              )}
            </div>
          </div>
          <div className="col col--is--12 col--xl--6 col--big">
            <Banner target="account.dashboard.banner" position="main" />

            <Services
              allPackages={allPackages}
              contract={contract}
              current_nr={this.props.current_nr}
              devices={devices}
              contract_id={contract_id}
            />
          </div>
        </div>
      </AdminContent>
    )
  }
}

function mapStateToProps(state) {
  return {
    contract_nr: state.contract_nr,
    current_nr: state.current_nr.value,
    contracts: state.contract.items,
    invoices: state.contract_invoices,
    inbox: state.inbox,
    packages: state.contract_packages,
    devices: state.contract_device,
    content: state.translation.content,
    help: state.translation.help,
  }
}

const enhance = compose(
  connect(mapStateToProps, {
    requestCustomerInbox,
    getContractInvoices,
    getContractInfo,
    getContractPackages,
    getContractDevices,
    getAdvertisement,
    startLoading,
    stopLoading,
  }),
  ContractHoc
  // WithLoader
)

export default enhance(Dashboard)

/*
export default (connect(mapStateToProps, {
    requestCustomerInbox,
    getContractInvoices,
    getContractInfo,
    getContractPackages,
    getContractDevices,
    getAdvertisement
})(ContractHoc(Dashboard)));
*/
