export const REQUEST_LOGIN = 'request-login'
export const RECEIVE_LOGIN = 'receive-login'
export const RECEIVE_LOGIN_FAILED = 'receive-login-failed'

export const REQUEST_LOGOUT = 'request-logout'
export const RECEIVE_LOGOUT = 'receive-logout'
export const RECEIVE_LOGOUT_FAILED = 'receive-logout-failed'

export const REQUEST_SIGN_UP = 'request-sign-up'
export const REQUEST_SIGN_UP_VALIDATION = 'request-sign-up-validation'
export const REQUEST_SIGN_UP_ACKNOWLEDGMENTS = 'request-sign-up-acknowledgments'
export const RECEIVE_SIGN_UP_ACKNOWLEDGMENTS = 'receive-sign-up-acknowledgments'

export const REQUEST_NEW_SERVICE_PORTFOLIOS = 'request-new-service-portfolios'
export const RECEIVE_NEW_SERVICE_PORTFOLIOS = 'receive-new-service-portfolios'
export const REQUEST_NEW_SERVICE_OPTIONS = 'request-new-service-options'
export const REQUEST_NEW_SERVICE_OPTIONS_FAILED =
  'request-new-service-options-failed'
export const RECEIVE_NEW_SERVICE_OPTIONS = 'receive-new-service-options'

// export const REQUEST_UPSELL_PORTFOLIOS = 'request-upsell-portfolios'
// export const RECEIVE_UPSELL_PORTFOLIOS = 'receive-upsell-portfolios'
export const REQUEST_UPSELL_OPTIONS = 'request-upsell-options'
export const REQUEST_UPSELL_OPTIONS_FAILED =
  'request-upsell-options-failed'
export const RECEIVE_UPSELL_OPTIONS = 'receive-upsell-options'

export const SELECT_PORTFOLIO = 'select-portfolio'
export const SELECT_PACKAGE_CORE = 'select-package-core'
export const SELECT_INSTALLATION = 'select-installation'
export const GO_TO_STEP = 'go-to-step'
export const GO_TO_STEP_UPSELL = 'go-to-step-upsell'
export const INC_DEVICE_TOTAL = 'inc-device-total'
export const DEC_DEVICE_TOTAL = 'dec-device-total'
export const RESET_DEVICE_TOTAL = 'reset-device-total'
export const FINISH_REGISTRATION = 'finish-registration'

export const REQUEST_SUBMIT_STEP_1 = 'request-submit-step-1'
export const RECEIVE_SUBMIT_STEP_1 = 'receive-submit-step-1'
export const RECEIVE_SUBMIT_STEP_1_FAILED = 'receive-submit-step-1-failed'
export const REQUEST_SUBMIT_STEP_2 = 'request-submit-step-2'
export const RECEIVE_SUBMIT_STEP_2 = 'receive-submit-step-2'
export const RECEIVE_SUBMIT_STEP_2_FAILED = 'receive-submit-step-2-failed'
export const REQUEST_SUBMIT_STEP_3 = 'request-submit-step-3'
export const RECEIVE_SUBMIT_STEP_3 = 'receive-submit-step-3'
export const RECEIVE_SUBMIT_STEP_3_FAILED = 'receive-submit-step-3-failed'

export const REQUEST_SUBMIT_UPSELL_STEP_1 = 'request-submit-upsell-step-1'
export const RECEIVE_SUBMIT_UPSELL_STEP_1 = 'receive-submit-upsell-step-1'
export const RECEIVE_SUBMIT_UPSELL_STEP_1_FAILED =
  'receive-submit-upsell-step-1-failed'
export const REQUEST_SUBMIT_UPSELL_STEP_2 = 'request-submit-upsell-step-2'
export const RECEIVE_SUBMIT_UPSELL_STEP_2 = 'receive-submit-upsell-step-2'
export const RECEIVE_SUBMIT_UPSELL_STEP_2_FAILED =
  'receive-submit-upsell-step-2-failed'
export const REQUEST_SUBMIT_UPSELL_STEP_3 = 'request-submit-upsell-step-3'
export const RECEIVE_SUBMIT_UPSELL_STEP_3 = 'receive-submit-upsell-step-3'
export const RECEIVE_SUBMIT_UPSELL_STEP_3_FAILED =
  'receive-submit-upsell-step-3-failed'

export const DESTROY_STATE = 'destroy-state'

export const REQUEST_CALL = 'request-call'
export const RECEIVE_CALL = 'receive-call'
export const RECEIVE_CALL_FAILED = 'receive-call-failed'

export const REQUEST_USER_INFO = 'request-user-info'
export const RECEIVE_USER_INFO = 'receive-user-info'
export const RECEIVE_USER_INFO_FAILED = 'receive-user-info-failed'

export const REQUEST_ACKNOWLEDGMENTS = 'request-acknowledgments'
export const RECEIVE_ACKNOWLEDGMENTS = 'receive-acknowledgments'
export const RECEIVE_ACKNOWLEDGMENTS_FAILED = 'receive-acknowledgments-failed'
export const TOGGLE_ACKNOWLEDGMENT_CONFIRM = 'toggle-acknowledgment-confirm'

export const REQUEST_PORTFOLIO = 'request-portfolio'
export const RECEIVE_PORTFOLIO = 'receive-portfolio'
export const RECEIVE_PORTFOLIO_FAILED = 'receive-portfolio-failed'

export const REQUEST_PORTFOLIO_DEVICES = 'request-portfolio-devices'
export const RECEIVE_PORTFOLIO_DEVICES = 'receive-portfolio-devices'
export const RECEIVE_PORTFOLIO_DEVICES_FAILED =
  'receive-portfolio-devices-failed'

export const REQUEST_PORTFOLIO_PACKAGES = 'request-portfolio-packages'
export const RECEIVE_PORTFOLIO_PACKAGES = 'receive-portfolio-packages'
export const RECEIVE_PORTFOLIO_PACKAGES_FAILED =
  'receive-portfolio-packages-failed'

export const REQUEST_CONTRACT = 'request-contract'
export const RECEIVE_CONTRACT = 'receive-contract'
export const RECEIVE_CONTRACT_UPDATE = 'receive-contract-update'
export const RECEIVE_CONTRACT_FAILED = 'receive-contract-failed'

export const REQUEST_CONTRACT_PACKAGES = 'request-contract-packages'
export const RECEIVE_CONTRACT_PACKAGES = 'receive-contract-packages'
export const RECEIVE_CONTRACT_PACKAGES_FAILED =
  'receive-contract-packages-failed'

export const REQUEST_CONTRACT_DEVICES = 'request-contract-devices'
export const RECEIVE_CONTRACT_DEVICES = 'receive-contract-devices'
export const RECEIVE_CONTRACT_DEVICES_FAILED = 'receive-contract-devices-failed'

export const REQUEST_CONTRACT_INVOICES = 'request-contract-invoices'
export const RECEIVE_CONTRACT_INVOICES = 'receive-contract-invoices'
export const RECEIVE_CONTRACT_INVOICES_FAILED =
  'receive-contract-invoices-failed'

export const REQUEST_CREATE_CONTACT = 'request-create-contact'
export const RECEIVE_CREATE_CONTACT = 'receive-create-contact'
export const RECEIVE_CREATE_CONTACT_FAILED = 'receive-create-contact-failed'

export const REQUEST_DELETE_CONTACT = 'request-delete-contact'
export const RECEIVE_DELETE_CONTACT = 'receive-delete-contact'
export const RECEIVE_DELETE_CONTACT_FAILED = 'receive-delete-contact-failed'

export const REQUEST_UPDATE_CONTACT = 'request-update-contact'
export const RECEIVE_UPDATE_CONTACT = 'receive-update-contact'
export const RECEIVE_UPDATE_CONTACT_FAILED = 'receive-update-contact-failed'
export const REQUEST_CONTRACT_INFO = 'request-contract-info'
export const RECEIVE_CONTRACT_INFO = 'receive-contract-info'
export const RECEIVE_CONTRACT_INFO_FAILED = 'receive-contract-info-failed'

export const RECEIVE_UPDATE_CONTRACT_ADDRESS = 'receive-update-contract-address'

export const RECEIVE_DELETE_CONTRACT_ADDRESS = 'receive-delete-contract-address'
export const RECEIVE_DELETE_CONTRACT_ADDRESS_FAILED =
  'receive-delete-contract-address-failed'

export const SET_MESSAGE = 'receive-error'
export const CLEAR_MESSAGE = 'clear-error'

export const SET_CURRENT_CONTRACT = 'set-current-contract'

export const RECEIVE_CONTRACT_INVOICE = 'receive-contract-invoice'
export const REQUEST_CONTRACT_INVOICE = 'request-contract-invoice'
export const RECEIVE_CONTRACT_INVOICE_FAILED = 'receive-contract-invoice-failed'

export const REQUEST_CONTRACT_PACKAGE_INFO = 'request-contract-package-info'
export const RECEIVE_CONTRACT_PACKAGE_INFO = 'receive-contract-package-info'
export const RECEIVE_CONTRACT_PACKAGE_INFO_FAILED =
  'receive-contract-package-info-failed'

export const START_LOADING = 'start-loading'
export const STOP_LOADING = 'stop-loading'

export const REQUEST_CONTACT_TYPES = 'request-contact-types'
export const RECEIVE_CONTACT_TYPES = 'receive-contact-types'
export const RECEIVE_CONTACT_TYPES_FAILED = 'receive-contact-types-failed'

export const REQUEST_UPSELL = 'request-upsell'
export const RECEIVE_UPSELL = 'receive-upsell'
export const RECEIVE_UPSELL_FAILED = 'receive-upsell-failed'

export const REACTIVATE_CARD_SUCCESS = 'reactivate-card-success'

export const RECEIVE_INBOX = 'receive-inbox'
export const REQUEST_INBOX = 'request-inbox'
export const RECEIVE_INBOX_FAILED = 'receive-inbox-failed'
export const RECEIVE_INBOX_OPENED = 'receive-inbox-opened'
export const RECEIVE_INBOX_NEW = 'receive-inbox-new'

export const RECEIVE_INBOX_MESSAGE = 'receive-inbox-message'
export const REQUEST_INBOX_MESSAGE = 'request-inbox-message'
export const RECEIVE_INBOX_MESSAGE_FAILED = 'receive-inbox-message-failed'

export const LEAVING_MODAL = 'leaving_modal'
export const CAN_LEAVE = 'can_leave'

export const RECEIVE_TRANSLATION = 'receive-translation'
export const REQUEST_TRANSLATION = 'request-translation'
export const RECEIVE_TRANSLATION_FAILED = 'receive-translation-failed'

export const RECEIVE_ADVERTISEMENT = 'receive-advertisement'
export const REQUEST_ADVERTISEMENT = 'request-advertisement'
export const RECEIVE_ADVERTISEMENT_FAILED = 'receive-advertisement-failed'

export const SET_AUTH = 'set-auth'
export const CLEAR_REDIRECT = 'clear-redirect'
export const WAS_ACTIVATED = 'was-activated'

export const RECEIVE_PIN = 'receive-pin'
export const REQUEST_PIN = 'request-pin'
export const RECEIVE_PIN_FAILED = 'receive-pin-failed'

export const RECEIVE_PIN_CONFIRMATION = 'receive-pin-confirmation'
export const REQUEST_PIN_CONFIRMATION = 'request-pin-confirmation'
export const RECEIVE_PIN_CONFIRMATION_FAILED = 'receive-pin-confirmation-failed'

export const START_REFRESHING_APP = 'start-refreshing-app'
export const STOP_REFRESHING_APP = 'stop-refreshing-app'
//export const RECEIVE_TOKEN_FAILED             = 'receive-token-failed';

/*
export const RECEIVE_TOKEN                    = 'receive-token-pin';
export const REQUEST_TOKEN                    = 'request-token-pin';
export const RECEIVE_TOKEN_FAILED             = 'receive-token-failed';
*/

export const SET_PAYMENT_ACTION = 'set-payment-action'
export const PA_NEW_CONTRACT = 'pa-new-contract'
export const PA_INVOICE = 'pa-invoice'

export const SET_REFRESHING_STEP = 'set-refreshing-step'
export const SET_REFRESHING_INTERVAL = 'set-refreshing-interval'

export const TRANSACTION_PUSH = 'transaction-push'

export const REQUEST_REWARDS = 'request-rewards'
export const REQUEST_REWARDS_FAILED = 'request-rewards-failed'
export const RECEIVE_REWARDS = 'receive-rewards'

export const REQUEST_SUBMIT_REWARD = 'request-submit-reward'
export const REQUEST_SUBMIT_REWARD_FAILED = 'request-submit-reward-failed'
export const REQUEST_SUBMIT_REWARD_SUCCESS = 'request-submit-reward-success'
export const LOYALTY_NEXT_STEP = 'loyalty-next-step'
