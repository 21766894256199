import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'

// import { Link } from 'react-router-dom';

import {
  // required,
  // renderField,
  passwordValidation,
  renderTooglePassword,
} from './../utils/form'

class ProfileSettingPasswordlForm extends Component {
  render() {
    const { handleSubmit, submitting } = this.props

    return (
      <div>
        <form onSubmit={handleSubmit}>
          <ul className="list list--6--2 align-center">
            <li className="list__row">
              <div className="list__label list__col">
                <label htmlFor="currentPassword">Staré heslo</label>
              </div>
              <div className="list__value list__col">
                <Field
                  id="currentPassword"
                  name="currentPassword"
                  component={renderTooglePassword}
                  type="password"
                  placeholder=""
                />
              </div>
            </li>
            <li className="list__row">
              <div className="list__label list__col">
                <label htmlFor="newPassword">Nové heslo</label>
              </div>
              <div className="list__value list__col">
                <Field
                  id="newPassword"
                  name="newPassword"
                  component={renderTooglePassword}
                  type="password"
                  placeholder=""
                />
              </div>
            </li>
            <li className="list__row">
              <div className="list__label list__col">
                <label htmlFor="pass2">Nové heslo</label>
                <div className="list__label__sub">
                  (zopakujte pro potvrzení)
                </div>
              </div>
              <div className="list__value list__col">
                <Field
                  id="pass2"
                  name="pass2"
                  component={renderTooglePassword}
                  type="password"
                  placeholder=""
                />
              </div>
            </li>
          </ul>

          <div className="footline--contact">
            <button
              disabled={submitting}
              className="btn btn--primary btn--next btn--block--xs"
              type="submit"
            >
              Uložit nové heslo
            </button>
          </div>
        </form>
      </div>
    )
  }
}

function validate(values) {
  const errors = {}

  if (!values.currentPassword) {
    errors.currentPassword = 'Prosím, vyplňte staré heslo.'
  }

  if (!values.newPassword) {
    errors.newPassword = 'Prosím, vyplňte toto pole.'
  }

  if (!values.pass2) {
    errors.pass2 = 'Prosím, vyplňte toto pole.'
  }

  if (
    values.pass2 &&
    values.newPassword &&
    values.pass2 !== values.newPassword
  ) {
    errors.pass2 = 'Hesla musí být stejná.'
  }

  if (values.pass2 && values.newPassword) {
    let err = passwordValidation(values.newPassword)

    if (err) {
      errors.newPassword = err
    }
  }

  return errors
}

export default reduxForm({
  form: 'form_profile_password',
  touchOnBlur: false,
  validate,
  /*
    ,
    onSubmitFail: errors => {

    }*/
  /*
    onSubmitSuccess: (result, dispatch, props) => {
        props.xxxxxxxxxxxxxxxxx = 'ok';
        //props.reset(props.navigation.key)
    }        */
})(ProfileSettingPasswordlForm)
