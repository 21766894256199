import React from 'react'

const ButtonMoreInfo = ({ toggleModal, description }) => {
  return (
    <button
      type="button"
      className="link"
      onClick={() => toggleModal(description)}
    >
      více info
    </button>
  )
}

export default ButtonMoreInfo
