import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import { compose } from 'redux'

import {
  //required,
  renderField,
  renderSelect,
  emailValidation,
  faxValidation,
  //mobileValidation,
  //landlineValidation,
  phoneValidation,
  renderPhoneField,
} from '../utils/form'

//import {dtApiText} from '../utils/functions';

import Loading from '../component/loading'

class ProfileContactForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      contactSubtypes: [],
      contactType: null,
      contactTypes: {},
    }
    this.changeType = this.changeType.bind(this)
  }

  changeType(event) {
    this.setState({
      contactSubtypes: this.props.freeSlots[event.target.value]
        ? Object.keys(this.props.freeSlots[event.target.value].subtypes).filter(
            (c) => {
              if (c === 'billing' && !this.props.hasContracts) {
                return false
              }
              return true
            }
          )
        : [],
      contactType: event.target.value,
    })
  }

  render() {
    const { handleSubmit, submitting } = this.props
    const types = Object.keys(this.props.freeSlots)

    return (
      <form onSubmit={handleSubmit}>
        {submitting && <Loading />}

        <div className="create-contact-step-1">
          <ul className="list list--action align-center list--modal">
            <li className="list__row">
              <div className="list__label list__col">
                <label htmlFor="type">Typ kontaktu</label>
              </div>
              <div className="list__value list__col">
                <Field
                  id="type"
                  name="type"
                  component={renderSelect}
                  options={types}
                  onChange={this.changeType}
                />
              </div>
            </li>

            {this.state.contactType && (
              <li className="list__row">
                <div className="list__label list__col">
                  <label htmlFor="subtype">Účel</label>
                </div>
                <div className="list__value list__col">
                  <Field
                    id="subtype"
                    name="subtype"
                    component={renderSelect}
                    options={this.state.contactSubtypes}
                  />
                </div>
              </li>
            )}

            {this.state.contactType && (
              <li className="list__row">
                <div className="list__label list__col">
                  <label htmlFor="value">Kontakt</label>
                </div>
                <div className="list__value list__col">
                  {['telephone', 'mobile'].includes(this.state.contactType) ? (
                    <Field
                      id="value"
                      name="value"
                      component={renderPhoneField}
                      mask="+42"
                      type="text"
                      label="+42_ ___ ___ ___"
                      validate={[phoneValidation]}
                    />
                  ) : (
                    <Field
                      id="value"
                      name="value"
                      component={renderField}
                      type="text"
                      placeholder=""
                    />
                  )}
                </div>
              </li>
            )}
          </ul>

          <div className="footline footline--btn">
            <button
              disabled={submitting}
              className="btn btn--primary btn--next btn--block--xs"
              type="submit"
            >
              Dále
            </button>
          </div>
        </div>
      </form>
    )
  }
}

function validate(values) {
  const errors = {}

  if (!values.type) {
    errors.type = 'Prosím, zvolte typ kontaktu.'
  }

  if (!values.subtype) {
    errors.subtype = 'Prosím, zvolte subtyp kontaktu.'
  }

  if (!values.value) {
    errors.value = 'Prosím, vyplňte kontakt.'
  } else {
    switch (values.type) {
      case 'email':
        errors.value = emailValidation(values.value)
        break
      case 'telephone':
        errors.value = phoneValidation(values.value, values)
        //errors.value = landlineValidation( values.value );
        break
      case 'mobile':
        errors.value = phoneValidation(values.value, values)
        //errors.value = mobileValidation( values.value );
        break
      case 'fax':
        errors.value = faxValidation(values.value)
        break

      // no default
    }
  }

  return errors
}

const mapStateToProps = (state) => {
  return {
    currentValues: state.form.form_profile_contact_create
      ? state.form.form_profile_contact_create.values
      : {},
    hasContracts: state.contract.ids.length > 0,
  }
}

export default compose(
  connect(mapStateToProps),
  reduxForm({
    form: 'form_profile_contact_create',
    touchOnBlur: false,
    validate,
    enableReinitialize: true,
  })
)(ProfileContactForm)
