import axios from 'axios'
import { SET_MESSAGE } from './types'

import {
  // RUIAN_API_URL,
  MESSAGE_ERROR,
  // MESSAGE_INFO,
  // MESSAGE_SUCCESS,
} from '../conf/constants'

import { instance } from '../conf/axios'

export function requestRegion(values = {}) {
  return function (dispatch, getState) {
    let params = new URLSearchParams()
    params.append('countryId', 1)
    values.name && params.append('name', values.name)

    return instance
      .get(
        `${process.env.REACT_APP_RUIAN_ENDPOINT}region${
          params.toString() && '?' + params.toString()
        }`,
        {
          public: true,
        }
      )
      .then((response) => {
        return Promise.resolve(response.data)
      })
      .catch((error) => {
        dispatch({
          type: SET_MESSAGE,
          payload: error,
          kind: MESSAGE_ERROR,
        })
        return Promise.reject(error)
      })
  }
}

export function requestCounty(values = {}) {
  return function (dispatch, getState) {
    let params = new URLSearchParams()
    params.append('regionId', values.regionId)
    values.name && params.append('name', values.name)

    return instance
      .get(
        `${process.env.REACT_APP_RUIAN_ENDPOINT}county${
          params.toString() && '?' + params.toString()
        }`,
        {
          public: true,
        }
      )
      .then((response) => {
        return Promise.resolve(response.data)
      })
      .catch((error) => {
        dispatch({
          type: SET_MESSAGE,
          payload: error,
          kind: MESSAGE_ERROR,
        })
        return Promise.reject(error)
      })
  }
}

export function requestCity(values = {}) {
  return function (dispatch, getState) {
    let params = new URLSearchParams()
    Object.keys(values).map((key) => params.append(key, values[key]))

    return instance
      .get(
        `${process.env.REACT_APP_RUIAN_ENDPOINT}city${
          params.toString() && '?' + params.toString()
        }`,
        {
          public: true,
        }
      )
      .then((response) => {
        return Promise.resolve(response.data)
      })
      .catch((error) => {
        dispatch({
          type: SET_MESSAGE,
          payload: error,
          kind: MESSAGE_ERROR,
        })
        return Promise.reject(error)
      })
  }
}

export function requestCityPart(values = {}) {
  return function (dispatch, getState) {
    let params = new URLSearchParams()
    Object.keys(values).map((key) => params.append(key, values[key]))

    return instance
      .get(
        `${process.env.REACT_APP_RUIAN_ENDPOINT}city-part${
          params.toString() && '?' + params.toString()
        }`,
        {
          public: true,
        }
      )
      .then((response) => {
        return Promise.resolve(response.data)
      })
      .catch((error) => {
        dispatch({
          type: SET_MESSAGE,
          payload: error,
          kind: MESSAGE_ERROR,
        })
        return Promise.reject(error)
      })
  }
}

export function requestStreet(values = {}) {
  return function (dispatch, getState) {
    let params = new URLSearchParams()
    Object.keys(values).map((key) => params.append(key, values[key]))

    return instance
      .get(
        `${process.env.REACT_APP_RUIAN_ENDPOINT}street${
          params.toString() && '?' + params.toString()
        }`,
        {
          public: true,
        }
      )
      .then((response) => {
        return Promise.resolve(response.data)
      })
      .catch((error) => {
        dispatch({
          type: SET_MESSAGE,
          payload: error,
          kind: MESSAGE_ERROR,
        })
        return Promise.reject(error)
      })
  }
}

export function requestAddress(values = {}) {
  return function (dispatch, getState) {
    let params = new URLSearchParams()
    Object.keys(values).forEach((key) => {
      values[key] && params.append(key, values[key].value || values[key])
    })

    return instance
      .get(
        `${process.env.REACT_APP_RUIAN_ENDPOINT}address${
          params.toString() && '?' + params.toString()
        }`,
        {
          public: true,
        }
      )
      .then((response) => {
        return Promise.resolve(response.data)
      })
      .catch((error) => {
        dispatch({
          type: SET_MESSAGE,
          payload: error,
          kind: MESSAGE_ERROR,
        })
        return Promise.reject(error)
      })
  }
}

export function requestFindAddress(values = {}) {
  return function (dispatch, getState) {
    return instance
      .get(`${process.env.REACT_APP_RUIAN_ENDPOINT}find`, { params: values })
      .then((response) => {
        return Promise.resolve(response.data)
      })
      .catch((error) => {
        dispatch({
          type: SET_MESSAGE,
          payload: error,
          kind: MESSAGE_ERROR,
        })
        return Promise.reject(error)
      })
  }
}

const makeRequestCreator = () => {
  let token

  return async (values) => {
    if (token) {
      token.cancel()
    }
    token = axios.CancelToken.source()
    try {
      const res = await instance.get(
        `${process.env.REACT_APP_RUIAN_ENDPOINT}find`,
        {
          params: values,
          cancelToken: token.token,
        }
      )
      return res.data
    } catch (error) {
      if (axios.isCancel(error)) {
        // TODO:
      }
    }
  }
}

export const findAddress = makeRequestCreator()
