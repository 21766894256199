import React, { Component } from 'react'
import { connect } from 'react-redux'

import PasswordRecoveryForm from '../../form/password-recovery-form'
import { requestRecoverPassword } from '../../actions/login'
import PublicLayout from '../../layout/public-layout'

import DigiModalContext from '../../context/digi-modal-context'
import ConfirmationNote from '../../component/confirmation-note'
import Loading from '../../component/loading'

class PasswordRecovery extends Component {
  static contextType = DigiModalContext

  constructor(props) {
    super(props)
    this.state = {
      successMessage: null,
      email: null,
      loading: false,
    }
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit(values) {
    this.setState({
      successMessage: null,
      email: values.email,
      loading: true,
    })
    this.props
      .requestRecoverPassword(values)
      .then(() => {
        this.context.toggleDigiModal(
          <ConfirmationNote
            text={
              'Instrukce pro obnovení hesla vám byly zaslány na vaší e-mailovou adresu.'
            }
            close={this.context.toggleDigiModal}
          />
        )
      })
      .catch((err) => {
        console.log(err)
      })
      .then(() => {
        this.setState({
          loading: false,
        })
      })
  }

  render() {
    return (
      <PublicLayout>
        {this.state.loading && <Loading />}
        <PasswordRecoveryForm
          onSubmit={this.handleSubmit}
          content={this.props.content}
        />
      </PublicLayout>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    content: state.translation.content,
  }
}

export default connect(mapStateToProps, {
  requestRecoverPassword,
})(PasswordRecovery)
