import { CLEAR_MESSAGE, SET_MESSAGE } from './types'

import { MESSAGE_ERROR, MESSAGE_WARNING, MESSAGE_INFO } from '../conf/constants'

export function clearError(contract_id) {
  return function(dispatch, getState) {
    dispatch({
      type: CLEAR_MESSAGE,
    })
  }
}

export function setError(error) {
  return function(dispatch, getState) {
    dispatch({
      type: SET_MESSAGE,
      payload: error,
      kind: MESSAGE_ERROR,
    })
  }
}

export function setWarning(error) {
  return function(dispatch, getState) {
    dispatch({
      type: SET_MESSAGE,
      payload: error,
      kind: MESSAGE_WARNING,
    })
  }
}

export function setInfo(error) {
  return function(dispatch, getState) {
    dispatch({
      type: SET_MESSAGE,
      payload: error,
      kind: MESSAGE_INFO,
    })
  }
}
