import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import sanitizeHtml from 'sanitize-html'
import { ALLOWED_ATTRIBUTES, ALLOWED_TAGS } from '../conf/constants'
import ContractHoc from '../hoc/contract-hoc'
import AdminContent from '../layout/admin-content'

class LoyaltyInfo extends Component {
  render() {
    const { content } = this.props
    return (
      <AdminContent
        advertisementTarget="profile.personal.data.banner"
        title="Věrnostní program"
        subtitle={content['content.loyalty.info.title']}
        link="/ucet/vernostni-program/prehled"
      >
        <div className="row">
          <div className="col col--is--12 col--big">
            <div
              className="admin__inner"
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(content['content.loyalty.info'], {
                  allowedTags: ALLOWED_TAGS,
                  allowedAttributes: ALLOWED_ATTRIBUTES,
                }),
              }}
            ></div>
          </div>
        </div>
      </AdminContent>
    )
  }
}

function mapStateToProps(state) {
  return {
    content: state.translation.content,
    loyalty: state.loyalty,
  }
}

export default compose(connect(mapStateToProps, {}), ContractHoc)(LoyaltyInfo)
