import FileSaver from 'file-saver'

import {
  REQUEST_CONTRACT_INVOICES,
  RECEIVE_CONTRACT_INVOICES,
  RECEIVE_CONTRACT_INVOICES_FAILED,
  SET_MESSAGE,
  RECEIVE_CONTRACT_INFO,
  RECEIVE_CONTRACT_INVOICE,
  REQUEST_CONTRACT_INVOICE,
  RECEIVE_CONTRACT_INVOICE_FAILED,
} from './types'

import {
  GOPAY_NOTIFY_URL,
  GOPAY_RETURN_URL,
  MESSAGE_ERROR,
  MESSAGE_SUCCESS,
  //API_DOMAIN
} from '../conf/constants'

import { instance } from '../conf/axios'

export function validatePayment(payment_id, with_message = true) {
  return async function (dispatch, getState) {
    try {
      const response = await instance.get(
        `customer/payment/validation/${payment_id}`,
        { public: true }
      )
      if (response.data.status === 'PAID' && with_message) {
        dispatch({
          type: SET_MESSAGE,
          payload: 'Platba byla úspěšně přijata',
          kind: MESSAGE_SUCCESS,
        })
      }
      return Promise.resolve(response.data)
    } catch (err) {
      if (with_message) {
        dispatch({
          type: SET_MESSAGE,
          payload: 'Chyba při validaci platby',
          kind: MESSAGE_ERROR,
        })
      }
      return Promise.reject(err)
    }
  }
}

export function createPayment(contract_id, invoice_id) {
  return async function (dispatch, getState) {
    // const { value: contract_nr } = getState().current_nr

    let values = {
      return_url: GOPAY_RETURN_URL,
      notification_url: GOPAY_NOTIFY_URL,
      invoices: [],
    }

    if (!Array.isArray(invoice_id)) {
      invoice_id = [invoice_id]
    }

    invoice_id.forEach((id) => {
      values.invoices.push(id)
    })

    try {
      const response = await instance.post(
        `customer/contract/${contract_id}/payment`,
        values
      )
      return Promise.resolve(response.data)
    } catch (error) {
      dispatch({
        type: SET_MESSAGE,
        payload: error,
        kind: MESSAGE_ERROR,
      })
      return Promise.reject(error)
    }
  }
}

export function downloadInvoice(contract_id, invoice_id) {
  return async function (dispatch, getState) {
    dispatch({
      type: REQUEST_CONTRACT_INVOICE,
      contract_id: contract_id,
    })
    // dispatch({
    //   type: START_LOADING,
    // })
    try {
      const response = await instance.get(
        `customer/contract/${contract_id}/invoice/${invoice_id}/pdf`
      )
      let data_url =
        'data:' + response.data.contentType + ';base64,' + response.data.base64
      fetch(data_url)
        .then((res) => res.blob())
        .then((blob) => {
          FileSaver.saveAs(blob, response.data.name)
        })
      dispatch({
        type: RECEIVE_CONTRACT_INVOICE,
        contract_id: contract_id,
      })
      // dispatch({
      //   type: STOP_LOADING,
      // })
      return Promise.resolve(response)
    } catch (error) {
      dispatch({
        type: SET_MESSAGE,
        payload: error,
        kind: MESSAGE_ERROR,
      })
      dispatch({
        type: RECEIVE_CONTRACT_INVOICE_FAILED,
        contract_id: contract_id,
      })
      // dispatch({
      //   type: STOP_LOADING,
      // })
      return Promise.reject(error)
    }
  }
}

export function submitPaymentSetup(contract_id, values) {
  return async function (dispatch) {
    //console.log( 'submitPaymentSetup' );
    //console.log( values );

    if (
      values.invoiceType === 'inkaso' &&
      values.invoiceTypeValue.includes(' ')
    ) {
      values.invoiceTypeValue = values.invoiceTypeValue.split(' ')[0]
    }

    try {
      const reponse = await instance.put(
        `customer/contract/${contract_id}`,
        values
      )
      dispatch({
        type: SET_MESSAGE,
        payload: 'Nastavení plateb bylo úspěšně uloženo.',
        kind: MESSAGE_SUCCESS,
      })
      dispatch({
        type: RECEIVE_CONTRACT_INFO,
        payload: reponse.data,
        contract_id: contract_id,
      })
      return reponse.data
    } catch (err) {
      // dispatch({
      //   type: SET_MESSAGE,
      //   payload: err,
      //   kind: MESSAGE_ERROR,
      // })
      return err
    }
  }
}

export function getContractInvoices(contract_id, page = 1) {
  return async function (dispatch) {
    dispatch({
      type: REQUEST_CONTRACT_INVOICES,
      contract_id: contract_id,
    })

    try {
      const response = await instance.get(
        `customer/contract/${contract_id}/invoice?page=${page}`
      )
      dispatch({
        type: RECEIVE_CONTRACT_INVOICES,
        payload: response.data.data,
        meta: response.data.meta,
        contract_id: contract_id,
      })
      return Promise.resolve(response.data)
    } catch (error) {
      dispatch({
        type: SET_MESSAGE,
        payload: error,
        kind: MESSAGE_ERROR,
      })
      dispatch({
        type: RECEIVE_CONTRACT_INVOICES_FAILED,
      })
      return Promise.reject(error)
    }
  }
}
