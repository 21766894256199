import {
  REQUEST_LOGIN,
  RECEIVE_LOGIN,
  REQUEST_SIGN_UP,
  REQUEST_SIGN_UP_VALIDATION,
  RECEIVE_USER_INFO,
  // RECEIVE_LOGOUT,
  REQUEST_SIGN_UP_ACKNOWLEDGMENTS,
  RECEIVE_SIGN_UP_ACKNOWLEDGMENTS,
  START_LOADING,
  STOP_LOADING,
  WAS_ACTIVATED,
} from './types'

import { requestCustomerInbox } from '../actions/inbox'
import { instance } from '../conf/axios'

import { USER_TOKEN_KEY } from '../conf/constants'
import { getContract } from './admin'

import { clearCookie, writeCookie } from '../utils/functions'
import { requestRewards } from './loyalty'

export function signInIfCookieExists() {
  return async function (dispatch) {
    dispatch({
      type: REQUEST_LOGIN,
    })
    //* musim ulozit {email: '', active: true}, coz probiha pri loginu
    dispatch({
      type: RECEIVE_LOGIN,
      payload: { email: '', active: true },
    })
    try {
      await dispatch(getContract())
      dispatch(requestCustomerInbox())
      dispatch(requestRewards())
      return await dispatch(requestUserInfo())
    } catch (error) {
      // alert('asdf')
      return error
    }
  }
}

export function requestLogin(values) {
  return async function (dispatch) {
    dispatch({
      type: REQUEST_LOGIN,
    })

    try {
      const response = await instance.post(`authenticate`, values, {
        public: true,
      })
      dispatch({
        type: RECEIVE_LOGIN,
        payload: { email: values.username, active: response.data.active },
      })
      // localStorage.setItem(USER_TOKEN_KEY, response.data.token)
      clearCookie(USER_TOKEN_KEY)
      writeCookie(USER_TOKEN_KEY, response.data.token, 1)
      return Promise.resolve(response)
    } catch (err) {
      return Promise.reject(err)
    }
  }
}

export function requestUserInfo() {
  return async function (dispatch) {
    try {
      const response = await instance.get('customer')
      dispatch({
        type: RECEIVE_USER_INFO,
        payload: response.data,
      })
      return Promise.resolve(response)
    } catch (err) {
      return Promise.reject(err)
    }
    /*
        .catch( error => {
            dispatch({
                type: RECEIVE_LOGOUT
            })
            return Promise.reject( error );
        })*/
  }
}

export function requestLoginAndUserInfo(values) {
  return async function (dispatch) {
    try {
      await dispatch(requestLogin(values))
      await dispatch(getContract())
      dispatch(requestCustomerInbox())
      dispatch(requestRewards())
      return await dispatch(requestUserInfo())
    } catch (error) {
      return error
    }
  }
}

export function requestSignUpAcknowledgments() {
  return async function (dispatch) {
    dispatch({
      type: START_LOADING,
    })
    dispatch({
      type: REQUEST_SIGN_UP_ACKNOWLEDGMENTS,
    })
    try {
      const res = await instance.get('sign/up/acknowledgment', { public: true })
      dispatch({
        type: RECEIVE_SIGN_UP_ACKNOWLEDGMENTS,
        acknowledgments: res.data.data,
      })
      dispatch({
        type: STOP_LOADING,
      })
      return Promise.resolve(res.data.data)
    } catch (err) {
      dispatch({
        type: STOP_LOADING,
      })
      return Promise.reject(err)
    }
  }
}

export function requestSignUp(values) {
  return async function (dispatch) {
    dispatch({
      type: REQUEST_SIGN_UP,
    })
    try {
      const res = await instance.post('sign/up', {
        name: values.firstname,
        surname: values.lastname,
        email: values.username,
        phone: values.phone,
        password: values.password,
        acknowledgments: Object.entries(values.acknowledgments).map(
          ([key, val]) => ({
            id: key,
            confirmed: val,
          })
        ),
      })
      return Promise.resolve(res.data)
    } catch (err) {
      return Promise.reject(err)
    }
  }
}

export function requestSignUpValidation(values) {
  return async function (dispatch, getState) {
    dispatch({
      type: START_LOADING,
    })
    dispatch({
      type: REQUEST_SIGN_UP_VALIDATION,
    })
    // const { auth } = getState().user
    try {
      const res = await instance.post(
        'sign/validation',
        {
          username: values.username,
          hash: values.hash,
        },
        { public: true }
      )
      dispatch({
        type: WAS_ACTIVATED,
      })
      // if (auth) {
      //   await dispatch(getContract())
      //   dispatch(requestCustomerInbox())
      //   await dispatch(requestUserInfo())
      // }
      dispatch({
        type: STOP_LOADING,
      })
      return Promise.resolve(res)
    } catch (err) {
      dispatch({
        type: STOP_LOADING,
      })
      return Promise.reject(err)
    }
  }
}
