import React, { Component } from 'react'
import { connect } from 'react-redux'

import { requestCall } from '../../actions/login'
import CallMeForm from '../../form/call-me-form'
import PublicLayout from '../../layout/public-layout'

class CallMe extends Component {
  constructor(props) {
    super(props)
    this.state = {
      successMessage: null,
    }
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit(values) {
    values.source = 'login'
    this.setState({
      successMessage: null,
    })
    return this.props
      .requestCall(values)
      .then(() => {
        this.setState({
          successMessage: (
            <div className="">
              Děkujeme, váš poždadavek byl odeslán. <br /> Náš operátor vás bude
              brzy kontaktovat.
            </div>
          ),
        })
      })
      .catch((err) => {
        console.log(err)
      })
  }

  render() {
    return (
      <PublicLayout>
        <CallMeForm
          successMessage={this.state.successMessage}
          onSubmit={this.handleSubmit}
          content={this.props.content}
        />
      </PublicLayout>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    content: state.translation.content,
  }
}

export default connect(
  mapStateToProps,
  {
    requestCall,
  }
)(CallMe)
