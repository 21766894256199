import React, { Component } from 'react'

import Loader from '../component/loading'

const WithLoader = (WrappedComponent) => {
  return class extends Component {
    constructor(props) {
      super(props)
      this.state = {
        loading: false,
        text: null,
      }
      this.startLoading = this.startLoading.bind(this)
      this.stopLoading = this.stopLoading.bind(this)
    }

    startLoading(text = null) {
      this.setState({
        loading: true,
        text,
      })
    }

    stopLoading() {
      this.setState({
        loading: false,
        text: null,
      })
    }

    render() {
      return (
        <React.Fragment>
          {this.state.loading && (
            <Loader className="global-loading" text={this.state.text} />
          )}
          <WrappedComponent
            {...this.props}
            stopLoading={this.stopLoading}
            startLoading={this.startLoading}
          />
        </React.Fragment>
      )
    }
  }
}

export default WithLoader
