import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import PublicLayout from '../../layout/public-layout'
import tellyLogo from '../../assets/images/logo-blue.svg'

class NotFoundFront extends Component {
  render() {
    return (
      <PublicLayout>
        <div className="into__box">
          <div className="intro__header">
            <div className="row">
              <div className="col">
                <img
                  className="intro__logo-mobile"
                  src={tellyLogo}
                  alt="intro logo"
                />
              </div>
              <div className="col">
                <h1 className="text--center">Nenalezeno</h1>
              </div>
            </div>
          </div>

          <div className="intro__text">
            <div className="row">
              <div className="col">
                <p>Je nám líto, stránka nebyla nalezena.</p>
                <p>
                  {' '}
                  Zpět na <Link to={'/'}>Přihlášení</Link>.
                </p>
              </div>
            </div>
          </div>
        </div>
      </PublicLayout>
    )
  }
}

export default NotFoundFront
