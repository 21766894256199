import React, { useState } from 'react'
import { Field, reduxForm } from 'redux-form'

// import { Link } from 'react-router-dom';
import IntroHeader from '../component/intro-header'

import {
  renderPhoneField,
} from '../utils/form'
import Loading from '../component/loading.js'

const BasePhoneForm = (props) => {
  const [loading, setLoading] = useState(false)
  const { handleSubmit, submitting, content, requestLogout } = props

  return (
    <React.Fragment>
      {(submitting || loading) && <Loading />}
      <form onSubmit={handleSubmit}>
        <IntroHeader label={content['content.login.user']} />

        {content['content.login.user.description'] && (
          <div className="intro__text">
            <div className="row">
              <div
                className="col"
                dangerouslySetInnerHTML={{
                  __html: content['content.login.user.missing.number.step1'],
                }}
              />
            </div>
          </div>
        )}

        <div className="form__row">
          <div className="form__item">
            <label htmlFor="username">Vaše tel. číslo:</label>
          </div>
          <div className="form__item">
              <Field
                id="value"
                name="value"
                component={renderPhoneField}
                mask="+42"
                type="text"
                label="+42_ ___ ___ ___"
              />
          </div>
        </div>

        <div className="intro__buttons intro__buttons--right">
          <button
            type="button"
            className="a--arrow--back black intro__links__link link--noborder"
            onClick={() => {
              setLoading(true)
              return requestLogout()
                .then((resp) => {
                  setLoading(false)
                  return Promise.resolve(resp)
                })
                .catch((err) => {
                  setLoading(true)
                  return Promise.resolve(err)
                })
            }}
          >
            Zpět
          </button>

          <button
            disabled={submitting}
            className="btn btn--primary btn--next"
            type="submit"
          >
            Pokračovat
          </button>
          {/*<Link className="link-primary a--arrow black" to={ '/prvni-prihlaseni' }>Ještě nemám heslo</Link>*/}
        </div>
      </form>

      {/*
            <div className="intro__links">
                <Link className="a--arrow green intro__links__link" to='/zapomenute-heslo'>Zapomněl jsem heslo</Link>
                <Link className="a--arrow green intro__links__link" to='/zavolejte-mi' >Zavolejte mi</Link>
            </div>
            */}
    </React.Fragment>
  )
}

function validate(values) {
  //console.log(values);
  const errors = {}

  if (!values.value) {
    errors.value = 'Prosím, vyplňte telefonní číslo.'
  }

  /*
    if(!values.username){
        errors.username = 'Prosím, vyplňte e-mail.';
    }

    if(!values.password){
        errors.password = 'Prosím, vyplňte heslo.';
    }
    */

  return errors
}

export default reduxForm({
  form: 'base_phone_form',
  touchOnBlur: false,
  validate,
})(BasePhoneForm)
