import React, { Component } from 'react'

import ButtonMoreInfo from '../button-more-info'
import { formatCurrency } from '../../utils/functions'

class Package extends Component {
  render() {
    const {
      item,
      selectPackage,
      toggleModal,
      description,
      tag,
      channelCount,
      channels,
      // isUpsell,
    } = this.props

    // TODO:  ParseContent

    const isDisabled = item.disabled || item.selected || item.active

    return (
      <div
        className={`
          packages__item
          ${item.disabled ? ' packages__item--disabled' : ''}
          ${item.selected ? ' packages__item--selected' : ''}`}
      >
        <h2 className="packages__item__title">{item.name}</h2>
        {tag && <div className="packages__tag">{tag}</div>}
        {channelCount && (
          <div className="packages__channel__count">{channelCount}</div>
        )}
        {channels && (
          <div
            className="packages__channels"
            dangerouslySetInnerHTML={{ __html: channels }}
          />
        )}
        <div className="packages__item__price__outer">
          {!item.disabled && !item.active && (
            <>
              <div
                className={`packages__item__price ${
                  item.discountVat > 0 && 'packages__item__price--discounted'
                }`}
              >
                {formatCurrency(item.priceOriginalVat, 0)} / měsíc
              </div>
              {item.discountVat > 0 && (
                <div className="packages__item__discount">
                  <span>{formatCurrency(item.priceVat, 0)}</span>
                </div>
              )}
            </>
          )}
        </div>
        <button
          className={
            'btn btn--primary' + (item.selected ? ' btn--item_selected' : '')
          }
          type="button"
          onClick={selectPackage}
          disabled={isDisabled}
        >
          {item.active ? 'Aktivní' : item.selected ? 'Vybraný' : 'Vybrat'}
        </button>
        <ButtonMoreInfo toggleModal={toggleModal} description={description} />
      </div>
    )
  }
}

export default Package
