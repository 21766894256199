import React, { Component } from 'react'
//import AdminHeader from "../component/admin-header";
import { connect } from 'react-redux'
import { compose } from 'redux'
import ContractHoc from '../hoc/contract-hoc'

import AdminContent from '../layout/admin-content'
//import Loading from '../component/loading'

import DigiModalContext from '../context/digi-modal-context'
import Reward from '../component/reward'

import { requestRewards } from '../actions/loyalty'
import Loading from '../component/loading'

import { postHeight } from '../utils/functions'
import sanitizeHtml from 'sanitize-html'

import benefitPlaceholder1 from '../assets/images/benefit_placeholder_1.png'
import benefitPlaceholder2 from '../assets/images/benefit_placeholder_2.png'
import benefitPlaceholder3 from '../assets/images/benefit_placeholder_3.png'
import benefitPlaceholder4 from '../assets/images/benefit_placeholder_4.png'
import { ALLOWED_ATTRIBUTES, ALLOWED_TAGS } from '../conf/constants'

class LoyaltyProgram extends Component {
  static contextType = DigiModalContext

  async componenDidMount() {
    await this.props.requestRewards()
  }

  render() {
    const { content, loyalty } = this.props
    return (
      <AdminContent
        advertisementTarget="profile.personal.data.banner"
        title="Věrnostní program"
        subtitle={content['content.loyalty.overview.title']}
        link="/ucet/vernostni-program/prehled"
      >
        {loyalty.loading && <Loading />}
        <div className="row">
          <div className="col col--is--12 col--big">
            <div className="admin__inner">
              <h2>
                {sanitizeHtml(content['content.loyalty.overview.heading'], {
                  allowedTags: [],
                  allowedAttributes: [],
                })}
              </h2>
              <div
                dangerouslySetInnerHTML={{
                  __html: sanitizeHtml(
                    content['content.loyalty.overview.description'],
                    {
                      allowedTags: [],
                      allowedAttributes: [],
                    }
                  ),
                }}
              ></div>
              <div className="row">
                <div className="col col--md--4">
                  <Reward
                    reward={{
                      title: sanitizeHtml(
                        content['content.loyalty.overview.banner1.title'],
                        {
                          allowedTags: [],
                          allowedAttributes: [],
                        }
                      ),
                      options: [],
                    }}
                    buttonText={sanitizeHtml(
                      content['content.loyalty.overview.banner1.button'],
                      {
                        allowedTags: [],
                        allowedAttributes: [],
                      }
                    )}
                    link="/ucet/vernostni-program/info"
                    image={benefitPlaceholder1}
                  />
                </div>
                <div className="col col--md--4">
                  <Reward
                    reward={{
                      title: sanitizeHtml(
                        content['content.loyalty.overview.banner2.title'],
                        {
                          allowedTags: [],
                          allowedAttributes: [],
                        }
                      ),
                      options: [],
                    }}
                    buttonText={sanitizeHtml(
                      content['content.loyalty.overview.banner2.button'],
                      {
                        allowedTags: [],
                        allowedAttributes: [],
                      }
                    )}
                    link="https://shop.telly.cz/"
                    externalLink={true}
                    image={benefitPlaceholder2}
                  />
                </div>
                <div className="col col--md--4">
                  <Reward
                    reward={{
                      title: sanitizeHtml(
                        content['content.loyalty.overview.banner3.title'],
                        {
                          allowedTags: [],
                          allowedAttributes: [],
                        }
                      ),
                      options: [],
                    }}
                    buttonText={sanitizeHtml(
                      content['content.loyalty.overview.banner3.button'],
                      {
                        allowedTags: [],
                        allowedAttributes: [],
                      }
                    )}
                    withModal={true}
                    modalContent={sanitizeHtml(
                      content['content.loyalty.overview.popup'],
                      {
                        allowedTags: ALLOWED_TAGS,
                        allowedAttributes: ALLOWED_ATTRIBUTES,
                      }
                    )}
                    link="/ucet/vernostni-program/partneri"
                    image={benefitPlaceholder3}
                  />
                </div>
                {loyalty.rewards.available.map((reward) => {
                  reward.title = sanitizeHtml(
                    content['content.loyalty.overview.banner4.title'],
                    {
                      allowedTags: [],
                      allowedAttributes: [],
                    }
                  )
                  return (
                    <div key={reward.id} className="col col--md--8">
                      <Reward
                        reward={reward}
                        description={sanitizeHtml(
                          content[
                            'content.loyalty.overview.banner4.description'
                          ],
                          {
                            allowedTags: ALLOWED_TAGS,
                            allowedAttributes: ALLOWED_ATTRIBUTES,
                          }
                        )}
                        bigLayoutSize={true}
                        link={
                          reward.options.length
                            ? '/ucet/vernostni-program/moje-odmeny'
                            : undefined
                        }
                        image={benefitPlaceholder4}
                      />
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </AdminContent>
    )
  }
}

function mapStateToProps(state) {
  return {
    content: state.translation.content,
    loyalty: state.loyalty,
  }
}

postHeight('.reward h2')

export default compose(
  connect(mapStateToProps, { requestRewards }),
  ContractHoc
)(LoyaltyProgram)
