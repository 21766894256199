import {
  REQUEST_CONTRACT,
  RECEIVE_CONTRACT,
  RECEIVE_CONTRACT_UPDATE,
  SET_REFRESHING_STEP,
  SET_REFRESHING_INTERVAL,
} from '../actions/types'

const INITIAL_STATE = {
  ids: [],
  items: {},
  loading: false,
  refreshing: false,
  refreshingInterval: 2000, // in ms
  refreshingStep: 0,
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case REQUEST_CONTRACT:
      return {
        ...state,
        loading: true,
      }

    case RECEIVE_CONTRACT:
      let items = {}
      let ids = []

      for (let value of action.payload) {
        items[value.id] = value
        ids.push(value.id)
      }

      return {
        ...state,
        ids: ids,
        items: items,
        loading: false,
      }

    case RECEIVE_CONTRACT_UPDATE:
      return {
        ...state,
        items: {
          ...state.items,
          [action.contract_id]: action.payload,
        },
      }

    case SET_REFRESHING_STEP:
      return {
        ...state,
        refreshingStep: action.payload,
      }

    case SET_REFRESHING_INTERVAL:
      return {
        ...state,
        refreshingInterval: action.payload,
      }

    // no default
  }
  return state
}
