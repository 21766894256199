import {
  REQUEST_CONTRACT_DEVICES,
  RECEIVE_CONTRACT_DEVICES,
  // RECEIVE_CONTRACT_DEVICES_FAILED,
  REACTIVATE_CARD_SUCCESS,
} from '../actions/types'

const INITIAL_STATE = {}

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case REACTIVATE_CARD_SUCCESS:
      const cards = state[action.contract_id].cards.map((item) => {
        if (action.smartcard_id !== item.id) {
          return item
        }

        return {
          ...item,
          status: true,
        }
      })

      return {
        ...state,
        [action.contract_id]: {
          ...state[action.contract_id],
          cards: cards,
        },
        //loading: true
      }

    case REQUEST_CONTRACT_DEVICES:
      return {
        ...state,
        loading: true,
      }

    case RECEIVE_CONTRACT_DEVICES:
      return {
        ...state,
        [action.contract_id]: action.payload,
      }

    // no default
  }
  return state
}
