import React, { Component } from 'react'

import ButtonMoreInfo from '../button-more-info'
import { formatCurrency } from '../../utils/functions'

class PackageSuppl extends Component {
  render() {
    const { item, toggleModal, channels, children } = this.props

    return (
      <div
        className={`
        supplementary-packages__item
        ${item.active ? 'supplementary-packages__item--disabled' : ''}
        ${item.selected ? 'supplementary-packages__item--selected' : ''}`}
      >
        <div className={'supplementary-packages__item__inner'}>
          <label className={'checkbox'}>
            {children}
            <span className={'supplementary-packages__item__name'}>
              {item.name}
            </span>
          </label>
          <div className={'supplementary-packages__item__info'}>
            <ButtonMoreInfo
              toggleModal={toggleModal}
              description={item.description}
            />
          </div>
          {channels && (
            <div
              className="supplementary-packages__channels"
              dangerouslySetInnerHTML={{ __html: channels }}
            />
          )}
        </div>
        <div className={'supplementary-packages__item__price__outer'}>
          {item.active && (
            <div className={'supplementary-packages__item__price'}>Aktivní</div>
          )}
          {!item.active && (
            <>
              <div
                className={`supplementary-packages__item__price ${
                  item.discountVat > 0 &&
                  'supplementary-packages__item__price--discounted'
                }`}
              >
                +&nbsp;
                {formatCurrency(item.priceOriginalVat, 0)} / měsíc
              </div>
              {item.discountVat > 0 && (
                <div className={'supplementary-packages__item__discount'}>
                  <span>
                    +&nbsp;
                    {formatCurrency(item.priceVat, 0)}
                  </span>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    )
  }
}

export default PackageSuppl
