import React from 'react'
import { connect } from 'react-redux'

import {
  formatPhone,
  findContactBaseMobile,
  dtApiText,
} from '../../utils/functions'
import { requestPin } from '../../actions/app'
import { modalStyle, modalStyleTiny } from '../../utils/modal-style'

import ProfileContactUpdateForm from '../../form/profile-contact-update-form'
import ProfileContactForm from '../../form/profile-contact-form'
import RemoveContactModal from './remove-contact-modal'
import DigiModalContext from '../../context/digi-modal-context'

import PinConfirmation from '../pin-confirmation'
import ConfirmationNote from '../confirmation-note'

const UserContacts = ({
  content = {},
  contacts = [],
  contract_info = {},
  contactTypes = {},
  current_nr = null,
  updateContact,
  createContact,
  remove,
  requestPin,
}) => {
  let takenSlots = {}
  let freeSlots = {}

  const baseMobileContact = findContactBaseMobile(contacts)

  contacts &&
    contacts.forEach((contact) => {
      takenSlots = {
        ...takenSlots,
        [contact.type]: takenSlots[contact.type]
          ? takenSlots[contact.type]
          : {},
      }

      if ('email' === contact.type && 'billing' === contact.subtype) {
        if (contract_info.invoice_email) {
          takenSlots[contact.type][contact.subtype] = 1
        }
      } else {
        takenSlots[contact.type][contact.subtype] = takenSlots[contact.type][
          contact.subtype
        ]
          ? takenSlots[contact.type][contact.subtype] + 1
          : 1
      }
    })

  contactTypes &&
    Object.keys(contactTypes).forEach((contactType) => {
      Object.keys(contactTypes[contactType].subtypes).forEach(
        (contactSubtype) => {
          if ('base' !== contactSubtype) {
            let obj = contactTypes[contactType].subtypes[contactSubtype]

            if (
              !takenSlots[contactType] ||
              !takenSlots[contactType][contactSubtype] ||
              (takenSlots[contactType] &&
                takenSlots[contactType][contactSubtype] &&
                takenSlots[contactType][contactSubtype] < obj.limit)
            ) {
              freeSlots = {
                ...freeSlots,
                [contactType]: {
                  ...freeSlots[contactType],
                  subtypes: {
                    ...(freeSlots[contactType] &&
                    freeSlots[contactType].subtypes
                      ? freeSlots[contactType].subtypes
                      : {}),
                    [contactSubtype]: {
                      ...contactTypes[contactType].subtypes[contactSubtype],
                    },
                  },
                },
              }
            }
          }
        }
      )
    }) //

  // vyfiltruje vsechny fakturacni e-maily, ktere nepatri ke zrovna zvolene smlouve
  let exists = false
  let contactsToShow = contacts.reduce((acc, current) => {
    if (
      !exists &&
      current.type === 'email' &&
      current.subtype === 'billing' &&
      current.value === contract_info.invoice_email
    ) {
      exists = true
      acc.push(current)
    } else if (!(current.type === 'email' && current.subtype === 'billing')) {
      acc.push(current)
    }
    return acc
  }, [])

  const pinConfirmationText = (values) => {
    //console.log('pinConfirmationText');
    let text = ''
    if ('email' === values.type) {
      text =
        content['content.profile.personal.data.change.mail.step1'] &&
        content['content.profile.personal.data.change.mail.step1'].replace(
          '%phone%',
          formatPhone(baseMobileContact)
        )
    } else if (
      'phone' === values.type ||
      'mobile' === values.type ||
      'telephone' === values.type
    ) {
      text =
        content['content.profile.personal.data.change.mobile.step1'] &&
        content['content.profile.personal.data.change.mobile.step1'].replace(
          '%phone%',
          formatPhone(values.value)
        )
    }
    return text
  }

  return (
    <DigiModalContext.Consumer>
      {({ setStyle, toggleDigiModal }) => (
        <React.Fragment>
          <div className="relative">
            <ul className="list list--action list--nowrap align-center">
              {contactsToShow.map((contact) => {
                let subtitle
                if (contact.type === 'email' && contact.subtype === 'billing') {
                  subtitle = (
                    <div className="list__label__sub">
                      {' '}
                      smlouva č. {current_nr}
                    </div>
                  )
                }

                return (
                  <li key={contact.id} className="list__row">
                    <div className="list__col--auto list__col">
                      <div className="list__row list__row--col">
                        <div className="list__col list__label">
                          {dtApiText(contact.type)} -{' '}
                          <span className="list__label__info">
                            {dtApiText(contact.subtype)}
                          </span>
                          {subtitle}
                        </div>

                        <div className="list__value">
                          {'mobile' === contact.type ||
                          'telephone' === contact.type
                            ? formatPhone(contact.value)
                            : contact.value}
                        </div>
                      </div>
                    </div>
                    <div className="list__action list__action--icons">
                      <button
                        className="btn--edit"
                        onClick={() => {
                          setStyle(modalStyle)
                          toggleDigiModal(
                            <ProfileContactUpdateForm
                              text={
                                content[
                                  `content.profile.personal.data.change.${
                                    contact.type === 'email' ? 'mail' : 'mobile'
                                  }.step0`
                                ]
                              }
                              initialValues={contact}
                              //requestPin={requestPin}
                              onSubmit={(values) =>
                                requestPin().then(() => {
                                  toggleDigiModal() // zavru
                                  setStyle(modalStyle)

                                  const text = pinConfirmationText(values)
                                  toggleDigiModal(
                                    <PinConfirmation
                                      text={text}
                                      confirmation={(validationPin) =>
                                        updateContact({
                                          ...values,
                                          validationPin: validationPin,
                                        }).then((response) => {
                                          toggleDigiModal()
                                          if (true === response.validation) {
                                            toggleDigiModal(
                                              <ConfirmationNote
                                                text={
                                                  content[
                                                    'content.profile.personal.data.change.mail.step2'
                                                  ] &&
                                                  content[
                                                    'content.profile.personal.data.change.mail.step2'
                                                  ].replace(
                                                    '%email%',
                                                    values.value
                                                  )
                                                }
                                                close={toggleDigiModal}
                                              />
                                            )
                                          } else {
                                            if (
                                              'phone' === values.type ||
                                              'mobile' === values.type ||
                                              'telephone' === values.type
                                            ) {
                                              toggleDigiModal(
                                                <ConfirmationNote
                                                  text={
                                                    content[
                                                      'content.profile.personal.data.change.mobile.confirm'
                                                    ] &&
                                                    content[
                                                      'content.profile.personal.data.change.mobile.confirm'
                                                    ]
                                                      .replace(
                                                        '%phone%',
                                                        formatPhone(
                                                          values.value
                                                        )
                                                      )
                                                      .replace(
                                                        '%phone_type%',
                                                        `${dtApiText(
                                                          values.type
                                                        )} - ${dtApiText(
                                                          values.subtype
                                                        ).toLowerCase()}`
                                                      )
                                                  }
                                                  close={toggleDigiModal}
                                                />
                                              )
                                            }
                                          }
                                        })
                                      }
                                    />
                                  )
                                })
                              }
                            />
                          )
                        }}
                      >
                        Změnit
                      </button>
                      {contact.subtype !== 'base' && (
                        <button
                          className="btn--delete"
                          onClick={() => {
                            setStyle(modalStyleTiny)
                            toggleDigiModal(
                              <RemoveContactModal
                                confirmDelete={() =>
                                  requestPin().then(() => {
                                    toggleDigiModal() // zavru
                                    setStyle(modalStyle)
                                    toggleDigiModal(
                                      <PinConfirmation
                                        text={
                                          content[
                                            'content.profile.personal.data.change.mail.step1'
                                          ] &&
                                          content[
                                            'content.profile.personal.data.change.mail.step1'
                                          ].replace(
                                            '%phone%',
                                            formatPhone(baseMobileContact)
                                          )
                                        }
                                        confirmation={(validationPin) =>
                                          remove(
                                            contact.id,
                                            validationPin
                                          ).then((res) => {
                                            if (res) {
                                              toggleDigiModal()
                                              toggleDigiModal(
                                                <ConfirmationNote
                                                  text={
                                                    content[
                                                      'content.profile.personal.data.change.common.confirm'
                                                    ]
                                                  }
                                                  close={toggleDigiModal}
                                                />
                                              )
                                            }
                                          })
                                        }
                                      />
                                    )
                                  })
                                }
                                close={() => toggleDigiModal()}
                              />
                            )
                          }}
                        >
                          Odebrat
                        </button>
                      )}
                    </div>
                  </li>
                )
              })}
            </ul>
          </div>

          <div className="footline--contact footline">
            {Object.values(freeSlots).length > 0 && (
              <button
                className="btn btn--primary  btn--next"
                onClick={() => {
                  setStyle(modalStyle)
                  toggleDigiModal(
                    <ProfileContactForm
                      onSubmit={(values) =>
                        requestPin().then(() => {
                          const text = pinConfirmationText(values)
                          toggleDigiModal()
                          toggleDigiModal(
                            <PinConfirmation
                              text={text}
                              confirmation={(validationPin) =>
                                createContact({
                                  ...values,
                                  validationPin: validationPin,
                                }).then((response) => {
                                  toggleDigiModal()
                                  if (true === response.validation) {
                                    toggleDigiModal(
                                      <ConfirmationNote
                                        text={
                                          content[
                                            'content.profile.personal.data.change.mail.step2'
                                          ] &&
                                          content[
                                            'content.profile.personal.data.change.mail.step2'
                                          ].replace('%email%', values.value)
                                        }
                                        close={toggleDigiModal}
                                      />
                                    )
                                  }
                                })
                              }
                            />
                          )
                        })
                      }
                      contactTypes={contactTypes}
                      freeSlots={freeSlots}
                    />
                  )
                }}
              >
                Přidat kontakt
              </button>
            )}
          </div>
        </React.Fragment>
      )}
    </DigiModalContext.Consumer>
  )
}

export default connect(null, {
  requestPin,
})(UserContacts)
