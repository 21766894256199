import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'

/*
import {
    renderField,
    preBankCodeValidation,
    bankAccountValidation,
    bankCodeValidation,
    deliveryEmailValidation
} from '../../utils/form';
*/

import RuianAddressFormContainer from '../ruian-address-form.js'
import PaymentSetupEmailForm from './payment-email.js'

class PaymentInvoiceTypeForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      values: {},
      ruianStep: 1,
    }
    this.onChangeInvoiceDelivery = this.onChangeInvoiceDelivery.bind(this)
    this.ruianStepCallback = this.ruianStepCallback.bind(this)
  }

  onChangeInvoiceDelivery(event) {
    this.setState({
      values: {
        ...this.state.values,
        invoiceDelivery: event.target.value,
      },
    })
  }

  ruianStepCallback(step) {
    this.setState({
      ruianStep: step,
    })
  }

  render() {
    const formData = { ...this.props.formData, ...this.state.values }

    return (
      <React.Fragment>
        {1 === this.state.ruianStep && (
          <form>
            <ul className="list list--action align-center list--modal">
              <li className="list__row">
                <div className="list__label list__col">
                  <label htmlFor="invoice-delivery">
                    Způsob zasílání faktury
                  </label>
                </div>
                <div className="list__value list__col">
                  <Field
                    id="invoice-delivery"
                    name="invoiceDelivery"
                    component="select"
                    onChange={this.onChangeInvoiceDelivery}
                  >
                    <option value="email">elektronická faktura</option>
                    <option value="post">papírová faktura</option>
                  </Field>
                </div>
              </li>
            </ul>
          </form>
        )}

        {'email' === formData.invoiceDelivery && (
          <PaymentSetupEmailForm
            initialValues={{
              invoiceEmail: formData.invoiceEmail,
            }}
            onSubmit={(values) =>
              this.props.submitSubForm({
                ...values,
                invoiceDelivery: formData.invoiceDelivery,
              })
            }
          />
        )}

        {'post' === formData.invoiceDelivery && (
          <div className="address-form">
            {1 === this.state.ruianStep && (
              <h4 className="address-label">Fakturační adresa</h4>
            )}

            <RuianAddressFormContainer
              requestPin={false}
              ruianStepCallback={this.ruianStepCallback}
              user={this.props.user}
              initialValues={{
                ...formData.invoiceAddress,
                no_address:
                  !formData.invoiceAddress ||
                  Object.values(formData.invoiceAddress).length === 0
                    ? true
                    : false,
              }}
              onSubmit={(values) =>
                this.props.submitSubForm({
                  invoiceAddress: values.no_address
                    ? {}
                    : {
                        ...values,
                      },
                  invoiceDelivery: formData.invoiceDelivery,
                })
              }
            />
          </div>
        )}
      </React.Fragment>
    )
  }
}

export default reduxForm({
  form: 'form_payment_invoice_type',
  touchOnBlur: false,
  //validate,
  enableReinitialize: true,
})(PaymentInvoiceTypeForm)
