import {
  START_LOADING,
  STOP_LOADING,
  RECEIVE_CONTACT_TYPES,
  LEAVING_MODAL,
  CAN_LEAVE,
  //SET_REDIRECT,
  //RECEIVE_TRANSLATION,
  CLEAR_REDIRECT,
  START_REFRESHING_APP,
  STOP_REFRESHING_APP,
} from '../actions/types'

const INITIAL_STATE = {
  loading: false,
  contactTypes: null,
  //leavingModal: false,
  canLeave: true,
  redirect: false,
  refreshing: false,
  //help: {},
  //translation: {},
  //auth: false
}

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case START_REFRESHING_APP:
      return {
        ...state,
        refreshing: true,
      }
    case STOP_REFRESHING_APP:
      return {
        ...state,
        refreshing: false,
      }
    case CLEAR_REDIRECT:
      return {
        ...state,
        redirect: false,
      }
    case START_LOADING:
      return {
        ...state,
        loading: true,
      }
    case STOP_LOADING:
      return {
        ...state,
        loading: false,
      }
    case RECEIVE_CONTACT_TYPES:
      return {
        ...state,
        contactTypes: action.payload,
      }
    case LEAVING_MODAL:
      return {
        ...state,
        leavingModal: action.payload,
      }
    case CAN_LEAVE:
      return {
        ...state,
        canLeave: action.payload,
      }

    // no default
  }
  return state
}
