import React, { Component } from 'react'

import { connect } from 'react-redux'
import { compose } from 'redux'
import ContractHoc from '../hoc/contract-hoc'
import { requestUserInfo } from '../actions/user'
import { requestPin } from '../actions/app'

import { requestCreateContact } from '../actions/profile'
import { requestLogout } from '../actions/login'

import BasePhoneForm from '../form/base-phone-form'
//import Loading from '../component/loading'

import DigiModalContext from '../context/digi-modal-context'
import PublicLayout from '../layout/public-layout'
import ConfirmationNote from '../component/confirmation-note'
import PinConfirmation from '../component/pin-confirmation'

class PromptBaseEmail extends Component {
  static contextType = DigiModalContext

  constructor(props) {
    super(props)
    this.state = {
      loading: false,
    }
  }

  render() {
    const { content } = this.props
    return (
      <PublicLayout>
        <BasePhoneForm
          content={this.props.content}
          requestLogout={this.props.requestLogout}
          //onSubmit={ values => this.props.requestCreateContact( { ...values, type: 'mobile', subtype: 'base' } ) }
          onSubmit={(values) =>
            this.props.requestPin({ phone: values.value }).then(() => {
              this.context.toggleDigiModal(
                <PinConfirmation
                  //text={ content['content.profile.personal.data.change.mail.step1'] && content['content.profile.personal.data.change.mail.step1'].replace( "%phone%", formatPhone( baseMobileContact ) ) }
                  text={content['content.login.user.missing.number.confirm']}
                  confirmation={(validationPin) =>
                    this.props
                      .requestCreateContact({
                        ...values,
                        validationPin: validationPin,
                        type: 'mobile',
                        subtype: 'base',
                      })
                      .then((resp) => {
                        this.context.toggleDigiModal()
                        this.context.toggleDigiModal(
                          <ConfirmationNote
                            loading={this.state.loading}
                            text={`Nastavení bylo uloženo.`}
                            close={() => {
                              this.setState({ loading: true })
                              this.props
                                .requestUserInfo()
                                .then((resp) => {
                                  this.setState({ loading: false })
                                  this.context.toggleDigiModal()
                                  return Promise.resolve(resp)
                                })
                                .catch((err) => {
                                  this.setState({ loading: false })
                                  this.context.toggleDigiModal()
                                  return Promise.reject(err)
                                })
                            }}
                          />
                        )
                        Promise.resolve(resp)
                      })
                  }
                />
              )
            })
          }
        />
      </PublicLayout>
    )
  }
}

function mapStateToProps(state) {
  return {
    content: state.translation.content,
  }
}

export default compose(
  connect(
    mapStateToProps,
    {
      requestCreateContact,
      requestPin,
      requestLogout,
      requestUserInfo,
    }
  ),
  ContractHoc
)(PromptBaseEmail)
