import React from 'react'
import { Field, reduxForm } from 'redux-form'

import {
  renderField,
  preBankCodeValidation,
  bankAccountValidation,
  bankCodeValidation,
  renderSelect,
} from '../../utils/form'

import { bankCodes } from '../../conf/bank-codes'

let PaymentInkasoForm = (props) => {
  const { handleSubmit } = props

  const codes = bankCodes.map((code) => `${code.code} (${code.name})`)

  return (
    <form className="form__bank__details" onSubmit={handleSubmit}>
      <ul className="list list--action align-center list--modal">
        <li className="list__row">
          <div className="list__label list__col">
            <label htmlFor="pre">Bankovní spojení</label>
          </div>
          <div className="list__value list__col">
            <Field
              id="pre"
              name="bankPre"
              component={renderField}
              type="text"
              label="Předčíslí účtu"
              validate={[preBankCodeValidation]}
            />
            &nbsp;
            <Field
              id="account"
              name="bankAccount"
              component={renderField}
              type="text"
              label="Číslo účtu"
              validate={[bankAccountValidation]}
            />
            &nbsp;
            <Field
              id="bank-code"
              name="bankCode"
              component={renderSelect}
              options={codes}
              label="Kód banky"
              validate={[bankCodeValidation]}
            >
              {/* {bankCodes && bankCodes.map(code=><option value={code.code}>{code.code} - {code.name}</option>)} */}
            </Field>
          </div>
        </li>
        {/* <li className="list__row">
                    <div className="list__label list__col">
                        <label htmlFor="account">
                            Číslo účtu
                        </label>
                    </div>
                    <div className="list__value list__col">

                    </div>
                </li>
                <li className="list__row">
                    <div className="list__label list__col">
                        <label htmlFor="bank-code">
                            Kód banky
                        </label>
                    </div>
                    <div className="list__value list__col">

                    </div>
                </li> */}
      </ul>

      <div className="">
        <button
          className="btn btn--primary btn--next btn--block--xs"
          type="submit"
        >
          OK
        </button>
      </div>
    </form>
  )
}

export default reduxForm({
  form: 'form_payment_inkaso',
  touchOnBlur: false,
  //validate,
  enableReinitialize: true,
})(PaymentInkasoForm)
