import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import sanitizeHtml from 'sanitize-html'
import { ALLOWED_TAGS, ALLOWED_ATTRIBUTES } from '../conf/constants'

import tellyLogo from '../assets/images/logo.svg'

const PublicHalfLayout = ({ content, children }) => {
  return (
    <div className="intro">
      <div className="intro__inner">
        <div className="intro__inner__logo intro__inner__logo--big">
          <img className="logo" src={tellyLogo} alt="" />
        </div>
        <div className="intro__inner__text intro__inner__text--register">
          <div
            className="intro__inner__text__title"
            dangerouslySetInnerHTML={{
              __html:
                content &&
                sanitizeHtml(content['content.signup.bigtext.title'], {
                  allowedTags: ALLOWED_TAGS,
                  allowedAttributes: ALLOWED_ATTRIBUTES,
                }),
            }}
          />
          <div
            className="intro__inner__text__subtitle"
            dangerouslySetInnerHTML={{
              __html:
                content &&
                sanitizeHtml(content['content.signup.bigtext.subtitle'], {
                  allowedTags: ALLOWED_TAGS,
                  allowedAttributes: ALLOWED_ATTRIBUTES,
                }),
            }}
          />
        </div>
        <div className="intro__box intro__box--big intro__box--register">
          <div className="intro__links__call-me">
            <Link to="/zavolejte-mi">Nevím si rady, zavolejte mi</Link>
          </div>
          {children}
        </div>
      </div>
    </div>
  )
}

function mapStateToProps(state) {
  return {
    content: state.translation.content,
  }
}

export default connect(
  mapStateToProps,
  {}
)(PublicHalfLayout)
