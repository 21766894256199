import {
  // REQUEST_PORTFOLIO,
  RECEIVE_PORTFOLIO,
  // RECEIVE_PORTFOLIO_FAILED
} from '../actions/types'

const INITIAL_STATE = {
  ids: [],
  items: {},
  loading: false,
}

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case RECEIVE_PORTFOLIO:
      let items = {}
      let ids = []

      for (let value of action.payload) {
        items[value.id] = value
        ids.push(value.id)
      }

      return {
        ...state,
        ids: ids,
        items: items,
        loading: false,
      }

    // no default
  }
  return state
}
