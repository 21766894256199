import React from 'react'
import { Link } from 'react-router-dom'
import BtnCardReactivation from '../../component/btn-card-reactivation'

const Services = ({
  contract_id,
  contract,
  allPackages,
  current_nr,
  devices = [],
}) => {
  return (
    <div className="admin__inner">
      <h2>Moje služby</h2>

      <div className="row row--is--center">
        <div className="col col--is--12 col--ts--6">
          <p className={`${0 === allPackages.length ? `rm--margin` : ``}`}>
            <strong>
              {contract && contract.portfolio && contract.portfolio.name}
            </strong>{' '}
            - Smlouva <strong>č. {current_nr}</strong>
          </p>

          {allPackages.length > 0 &&
            allPackages.map((item) => (
              <p key={item.id} className="rm--margin">
                <strong>{item.name}</strong>
              </p>
            ))}
        </div>
        <div className="col col--is--12 col--ts--6 text--right">
          {/* <Link
            className="btn btn--primary btn--next btn--block--xs"
            to="/ucet/moje-sluzby"
          >
            Aktivovat nové služby
          </Link> */}
        </div>
      </div>
      <hr />
      {devices[contract_id] &&
        devices[contract_id].cards.length > 0 &&
        devices[contract_id].cards.map((card) => (
          <div key={card.id}>
            <div className="row row--is--center">
              <div className="col col--is--12 col--ts--6">
                <p className="rm--margin">
                  Číslo smart karty: <strong>{card.serial}</strong>
                </p>
              </div>
              <div className="col col--is--12 col--ts--6 text--right">
                <BtnCardReactivation
                  className="btn btn--primary btn--next btn--block--xs"
                  contract_id={contract_id}
                  card={card}
                />
              </div>
            </div>
            <hr />
          </div>
        ))}
      <Link className="a--arrow--green" to="/ucet/moje-sluzby/prehled-sluzeb">
        Více informací o mých službách
      </Link>
    </div>
  )
}

export default Services
