import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware, compose } from 'redux'
import reduxThunk from 'redux-thunk'
import jwtDecode from 'jwt-decode'
import reducers from './reducers/index'
import App from './container/app'
import { APP_TOKEN_KEY, USER_TOKEN_KEY } from './conf/constants'
import { instance } from './conf/axios'
import {
  dtApiText,
  isGTMEnabled,
  getCookie,
  writeCookie,
  clearCookie,
} from './utils/functions'

import * as Sentry from '@sentry/react'

import {
  RECEIVE_LOGOUT,
  START_REFRESHING_APP,
  STOP_REFRESHING_APP,
} from './actions/types'
import { refreshToken, issueAppToken } from './actions/app'
import './assets/styles/main.scss'

import { toast } from 'react-toastify'

import 'moment/locale/cs'

import TagManager from 'react-gtm-module'
import { signInIfCookieExists } from './actions/user'

Sentry.init({
  dsn:
    'https://fab51d41cfff4f9181e7ec2bd1f12c5b@o292433.ingest.sentry.io/5303247',
})

// GTM initialization
if (isGTMEnabled()) {
  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_ID, // GTM-TXZPM8S
  }

  TagManager.initialize(tagManagerArgs)
}
// end GTM initialization

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['form', 'message'],
  //stateReconciler: autoMergeLevel2
}

const pReducer = persistReducer(persistConfig, reducers)
//const pReducer = reducers;

let store
const middlewares = []
middlewares.push(reduxThunk)

if ('development' === process.env.NODE_ENV) {
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
  store = createStore(
    pReducer,
    {},
    composeEnhancers(applyMiddleware(...middlewares))
  )
} else {
  store = createStore(pReducer, {}, compose(applyMiddleware(...middlewares)))
}

const persistor = persistStore(store)

let token_storage_key = ''

let refreshingToken = false
let originalRequest = {}

const createRequestInterceptor = () => {
  //console.log('createRequestInterceptor');

  const requestInterceptor = instance.interceptors.request.use(
    (config) => {
      if (refreshingToken) {
        return
      }

      //* pokud ucet neni aktivni, zakazeme urcite requesty
      if (
        store.getState().user.auth &&
        !store.getState().user.active &&
        !config.url.includes('authenticate') &&
        !config.url.includes('logout') &&
        !config.url.includes('sign/up') &&
        !config.url.includes('sign/validation') &&
        !config.url.includes('sign/up/acknowledgment') &&
        !config.url.includes('customer/translation') &&
        !config.url.includes('customer/account/confirm/email')
      ) {
        // console.log(config.url)
        return Promise.reject('restricted')
      }

      originalRequest = config

      token_storage_key = config.public ? APP_TOKEN_KEY : USER_TOKEN_KEY
      const token = getCookie(token_storage_key)

      if (!token || token === 'undefined') {
        // 'undefined' string pochazi pravdepodobne z api (naseho login.php ?)
        instance.interceptors.request.eject(requestInterceptor)
        return store
          .dispatch(issueAppToken())
          .then((token) => {
            // localStorage.setItem(APP_TOKEN_KEY, token)
            clearCookie(APP_TOKEN_KEY)
            writeCookie(APP_TOKEN_KEY, token)
            originalRequest.headers.Authorization = `Bearer ${token}`
            createRequestInterceptor()
            return Promise.resolve(originalRequest)
          })
          .catch((err) => {
            console.log(err)
            createRequestInterceptor()
            return Promise.reject(err)
          })
      } else {
        const decodedToken = jwtDecode(token)
        const currentTime = parseInt(Date.now() / 1000)
        const expireTime = decodedToken.exp
        let timeToExpire = expireTime - currentTime

        //? testovani tokenu
        // timeToExpire -= 3940
        // console.log(timeToExpire)

        if (
          timeToExpire < 100 &&
          (timeToExpire > 0 || token_storage_key === APP_TOKEN_KEY)
        ) {
          refreshingToken = true

          store.dispatch({
            type: START_REFRESHING_APP,
          })
          clearCookie(APP_TOKEN_KEY)

          instance.interceptors.request.eject(requestInterceptor)

          return store.dispatch(issueAppToken()).then(() => {
            // originalRequest.headers.Authorization = `Bearer ${app_token}`
            return store
              .dispatch(refreshToken(token))
              .then((token) => {
                // localStorage.setItem(token_storage_key, token)
                clearCookie(token_storage_key)
                writeCookie(token_storage_key, token)
                createRequestInterceptor()
                refreshingToken = false

                store.dispatch({
                  type: STOP_REFRESHING_APP,
                })

                // const cancelToken = instance.cancelToken
                // originalRequest.cancelToken = cancelToken
                originalRequest.headers.Authorization = `Bearer ${token}`

                // abort original request (po refreshi se nacte cely puvodni container, tudiz se znovu pustí všechny requesty v componentDidMount s novym tokenem)
                return Promise.resolve(originalRequest)
              })
              .catch((err) => {
                //console.log(err);

                if (APP_TOKEN_KEY === token_storage_key) {
                  //createRequestInterceptor();
                  //localStorage.removeItem(APP_TOKEN_KEY);

                  //console.log('app token expired');
                  return store
                    .dispatch(issueAppToken())
                    .then((token) => {
                      // localStorage.setItem(APP_TOKEN_KEY, token)
                      clearCookie(APP_TOKEN_KEY)
                      writeCookie(APP_TOKEN_KEY, token)
                      originalRequest.headers.Authorization = `Bearer ${token}`

                      refreshingToken = false

                      store.dispatch({
                        type: STOP_REFRESHING_APP,
                      })

                      createRequestInterceptor()
                      return Promise.resolve(originalRequest)
                    })
                    .catch((err) => {
                      refreshingToken = false

                      store.dispatch({
                        type: STOP_REFRESHING_APP,
                      })

                      //console.log(err);
                      createRequestInterceptor()
                      return Promise.reject(err)
                    })
                } else {
                  refreshingToken = false

                  store.dispatch({
                    type: STOP_REFRESHING_APP,
                  })

                  clearCookie(USER_TOKEN_KEY)
                  store.dispatch({
                    type: RECEIVE_LOGOUT,
                  })
                  createRequestInterceptor()
                  return Promise.reject(err)
                }
              })
          })
        }
      }
      config.headers.Authorization = `Bearer ${token}`
      return config
    },
    (err) => {
      return Promise.reject(err)
    }
  )
}

createRequestInterceptor()

const ErrorComponent = (props) => {
  const { message = '', errors = {} } = props

  //console.log(errors);

  return (
    <div className="toast-body--error">
      <strong>{dtApiText(message)}</strong>
      {Object.keys(errors).length > 0 && (
        <ul>
          {Object.keys(errors).map((key, index) => (
            <li key={index}>
              {dtApiText(key)}
              <ul>
                {errors[key].map((err, index) => (
                  <li key={index}>{dtApiText(err)}</li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

instance.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response
  },
  function (error) {
    if (
      error &&
      error.request &&
      (error.request.responseURL.includes('customer/new-service/step-2') ||
        error.request.responseURL.includes('customer/new-service/step-3'))
    ) {
      return Promise.reject(error)
    }

    if (error && error.response && error.response.status) {
      if (
        401 === error.response.status &&
        store.getState().user.auth &&
        !error.request.responseURL.includes('sign/validation') &&
        !error.request.responseURL.includes('customer/new-service/step-3') &&
        !error.request.responseURL.includes('customer/translation')
      ) {
        clearCookie(USER_TOKEN_KEY)
        store.dispatch({
          type: RECEIVE_LOGOUT,
        })
        // toast.error(<ErrorComponent message={'Nesprávné uživatelské jméno nebo heslo.'} errors={{}} />)
      }

      let message = ''
      let errors = {}

      if (error.response.data && error.response.data.message) {
        message = error.response.data.message
        if (typeof error.response.data.errors === 'object') {
          errors = error.response.data.errors
        }
      }

      if (message === 'Token has expired') return Promise.reject(error)

      // Chyba při validaci platby
      if (
        message === 'Not Found' &&
        error.request.responseURL.includes('customer/payment/validation')
      ) {
        toast.error(
          <ErrorComponent
            message={'Chyba při validaci platby'}
            errors={errors}
          />
        )
        return Promise.reject(error)
      }

      if (error.response.data.status_code < 500) {
        toast.error(<ErrorComponent message={message} errors={errors} />)
      } else {
        toast.error(
          <ErrorComponent
            message={
              'Během zpracování Vašeho požadavku došlo k neočekávané chybě, zkuste to znovu prosím později. Pokud problém přetrvává, kontaktujte zákaznickou linku Telly.'
            }
          />
        )
      }
    }

    return Promise.reject(error)
  }
)

//////////////////////////////////////////////////
// SSO AUTOLOGIN START
//////////////////////////////////////////////////
const userToken = getCookie(USER_TOKEN_KEY)
if (userToken !== null) {
  console.log('SSO autologin')
  store.dispatch(signInIfCookieExists())
}
//////////////////////////////////////////////////
// SSO AUTOLOGIN END
//////////////////////////////////////////////////

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor} loading={null}>
      <Router>
        <App />
      </Router>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
)

/*
ReactDOM.render(
    <Provider store={store}>

            <Router>
                <App />
            </Router>

    </Provider>,
    document.getElementById('root')
);
*/
