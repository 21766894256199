import React, { Component } from 'react'
//import ReactDOM from 'react-dom';
//import {Link} from 'react-router-dom';
// import AdminHeader from '../component/admin-header'
import { connect } from 'react-redux'
import Loading from '../component/loading'
import sanitizeHtml from 'sanitize-html'
import moment from 'moment'
import { ALLOWED_TAGS, ALLOWED_ATTRIBUTES } from '../conf/constants'

//import Banner from '../component/banner';

import AdminContent from '../layout/admin-content'

import { requestCustomerInboxMessage } from '../actions/inbox'

const Message = (props) => {
  const { message } = props

  return (
    <article className="admin__inner relative message">
      <header className="message__header">
        <div className="message__header__headline">
          {message.author && <div>Od {message.author}</div>}
          <div>{moment(message.created_at.date).format('L H:m')}</div>
        </div>
        <h2 className="message__header__label">{message.subject}</h2>
      </header>
      <div className="message__body">
        <div
          dangerouslySetInnerHTML={{
            __html: sanitizeHtml(message.content, {
              allowedTags: ALLOWED_TAGS,
              allowedAttributes: ALLOWED_ATTRIBUTES,
            }),
          }}
        />
      </div>
    </article>
  )
}

class InboxMessage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 1,
      opened: null,
    }
  }

  componentDidMount() {
    this.props.requestCustomerInboxMessage(this.props.match.params.id)
  }

  render() {
    const { content, help } = this.props
    return (
      <AdminContent
        advertisementTarget="profile.messages.detail.banner"
        title="Zprávy"
        subtitle={content['content.profile.message.detail.title']}
        help={help['help.profile.message.detail.help']}
        link="/ucet/profil/zpravy"
      >
        <div className="row">
          <div className="col col--is--12">
            {this.props.loading && <Loading />}

            {this.props.detail[this.props.match.params.id] && (
              <Message
                message={this.props.detail[this.props.match.params.id]}
              />
            )}
          </div>
        </div>

        {/*
                    <Banner target="profile.message.detail" position="bottom" />

                </div>
                */}
      </AdminContent>
    )
  }
}

function mapStateToProps(state) {
  return {
    //pagination: state.inbox.pagination,
    //items: state.inbox.items,
    //pages: state.inbox.pages,
    content: state.translation.content,
    help: state.translation.help,
    detail: state.inbox.detail,
    loading: state.inbox.loading,
  }
}

export default connect(mapStateToProps, {
  requestCustomerInboxMessage,
})(InboxMessage)
