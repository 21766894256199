import {
  // REQUEST_CONTRACT_PACKAGE_INFO,
  RECEIVE_CONTRACT_PACKAGE_INFO,
  // RECEIVE_CONTRACT_PACKAGE_INFO_FAILED
} from '../actions/types'

const INITIAL_STATE = {}

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case RECEIVE_CONTRACT_PACKAGE_INFO:
      return action.payload

    // no default
  }
  return state
}
