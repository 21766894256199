import React from 'react'
import { Field, reduxForm } from 'redux-form'

import { Link } from 'react-router-dom'

import { mobileValidation, renderPhoneField } from '../utils/form'
import Loading from '../component/loading'
import IntroHeader from '../component/intro-header'

const CallMeForm = (props) => {
  const { handleSubmit, submitting, error, content, successMessage } = props
  return (
    <React.Fragment>
      {submitting && <Loading />}
      <form onSubmit={handleSubmit}>
        <IntroHeader label={content['content.login.callback']} />

        {content['content.login.callback.description'] && (
          <div className="intro__text">
            <div className="row">
              <div
                className="col"
                dangerouslySetInnerHTML={{
                  __html: content['content.login.callback.description'],
                }}
              />
            </div>
          </div>
        )}

        <div className="form__row form__row--fluid">
          <div className="form__item">
            <label htmlFor="your_phone">Váše telefonní číslo:</label>
          </div>

          {/* <Field
            id="your_phone"
            name="phone"
            component={renderPhoneField}
            type="text"
            placeholder=""
            validate={[mobileValidation]}
          /> */}
          <Field
            id="your_phone"
            name="phone"
            component={renderPhoneField}
            mask="+42"
            type="text"
            label="+42_ ___ ___ ___"
            validate={[mobileValidation]}
          />
        </div>

        {error && (
          <div className="spacer spacer--20 validation--block alert">
            {error}
          </div>
        )}

        {successMessage && (
          <div className="spacer spacer--20 validation--block info">
            {successMessage}
          </div>
        )}

        <div className="intro__buttons intro__buttons--right">
          <button
            disabled={submitting}
            className="btn btn--primary"
            type="submit"
          >
            Odeslat
          </button>
        </div>
      </form>

      <div className="intro__links">
        <Link className="a--arrow--back green intro__links__link" to="/">
          Zpět na přihlášení
        </Link>
      </div>
    </React.Fragment>
  )
}

function validate(values) {
  const errors = {}
  if (!values.phone) {
    errors.phone = 'Prosím, vyplňte telefonní číslo.'
  }
  return errors
}

export default reduxForm({
  form: 'form_call_me_not_logged',
  touchOnBlur: false,
  validate,
})(CallMeForm)
