import {
  RECEIVE_CONTRACT_INFO,
  RECEIVE_DELETE_CONTRACT_ADDRESS,
  RECEIVE_UPDATE_CONTRACT_ADDRESS,
  RECEIVE_CONTRACT_INFO_FAILED,
  REQUEST_CONTRACT_INFO,
} from '../actions/types'

const INITIAL_STATE = {}

export default function(state = INITIAL_STATE, action) {
  const address_names = {
    installAddress: 'install_address',
    invoiceAddress: 'invoice_address',
  }

  switch (action.type) {
    case REQUEST_CONTRACT_INFO:
      return {
        ...state,
        [action.contract_id]: {
          ...state[action.contract_id],
          loading: true,
        },
      }

    case RECEIVE_CONTRACT_INFO_FAILED:
      return {
        ...state,
        [action.contract_id]: {
          ...state[action.contract_id],
          loading: false,
        },
      }

    case RECEIVE_CONTRACT_INFO:
      return {
        ...state,
        [action.contract_id]: {
          ...action.payload,
          loading: false,
        },
      }

    case RECEIVE_DELETE_CONTRACT_ADDRESS:
      return {
        ...state,
        [action.contract_id]: {
          ...state[action.contract_id],
          [address_names[action.address_type]]: null,
        },
      }

    case RECEIVE_UPDATE_CONTRACT_ADDRESS:
      return {
        ...state,
        [action.contract_id]: {
          ...state[action.contract_id],
          [address_names[action.address_type]]:
            action.payload[address_names[action.address_type]],
        },
      }

    // no default
  }
  return state
}
