import React from 'react'
import { Field, reduxForm } from 'redux-form'

import { Link } from 'react-router-dom'

import {
  renderField,
  passwordValidation,
  renderCheckbox,
  renderTooglePassword,
} from './../utils/form'
import Loading from '../component/loading'
import IntroHeader from '../component/intro-header'
import AckSC from '../component/AckSC'

const FirstLoginFormFirstStep = (props) => {
  const { handleSubmit, submitting, error, content, acks } = props

  return (
    <React.Fragment>
      {submitting && <Loading />}

      <form onSubmit={handleSubmit}>
        <IntroHeader label={content['content.login.first.credential']} />

        {!props.successMessage && (
          <React.Fragment>
            <div className="intro__text">
              <div className="row">
                <div
                  className="col"
                  dangerouslySetInnerHTML={{
                    __html: content['content.login.first.description'],
                  }}
                />
              </div>
            </div>

            <div className="form__row">
              <div className="form__item">
                <label htmlFor="email">Váš e-mail:</label>
              </div>
              <div className="form__item">
                <Field
                  id="email"
                  name="email"
                  component={renderField}
                  type="text"
                  placeholder=""
                />
              </div>
            </div>

            <div className="form__row">
              <div className="form__item">
                <label htmlFor="contract_no">Číslo smlouvy:</label>
              </div>
              <div className="form__item">
                <Field
                  id="contract"
                  name="contract"
                  component={renderField}
                  type="text"
                  placeholder=""
                />
              </div>
            </div>

            <div className="form__row">
              <div className="form__item">
                <label htmlFor="pass1">Heslo:</label>
              </div>
              <div className="form__item">
                <Field
                  id="pass1"
                  name="pass1"
                  component={renderTooglePassword}
                  type="password"
                  placeholder=""
                />
              </div>
            </div>
            <div className="form__row mb20">
              <div className="form__item">
                <label htmlFor="pass2">Potvrzení hesla:</label>
              </div>
              <div className="form__item">
                <Field
                  id="pass2"
                  name="pass2"
                  component={renderTooglePassword}
                  type="password"
                  placeholder=""
                />
              </div>
            </div>

            <div className="form__row">
              <div className="form__item" />
              <div className="form__item">
                <p className="small-note">
                  Heslo musí splňovat tyto požadavky: 8 znaků, velká a malá
                  písmena a alespoň jeden speciální znak.{' '}
                </p>
              </div>
            </div>

            <div className="form__row mb20">
              {acks &&
                acks.map((ack) => (
                  <div className="form__item" key={ack.id}>
                    <AckSC ack={ack}>
                      <Field
                        id={`acknowledgments[${ack.id}]`}
                        name={`acknowledgments[${ack.id}]`}
                        component={renderCheckbox}
                        className={'acknowledgment'}
                        validate={[]}
                        checked={ack.required && ack.selected}
                        disabled={ack.required && ack.selected}
                        label={ack.description}
                        // TODO: dodelat disabled do renderovaci komponenty
                      />
                    </AckSC>
                  </div>
                ))}
            </div>
          </React.Fragment>
        )}

        {error && (
          <div className="spacer spacer--20 validation--block alert">
            {error}
          </div>
        )}

        {props.successMessage && (
          <div className="spacer spacer--20 validation--block info">
            {props.successMessage}
          </div>
        )}

        {!props.successMessage && (
          <div className="intro__buttons intro__buttons--right">
            <button
              disabled={submitting}
              className="btn btn--primary"
              type="submit"
            >
              Registrovat
            </button>
          </div>
        )}
      </form>

      <div className="intro__links">
        <Link className="a--arrow--back green intro__links__link" to="/">
          Zpět na přihlášení
        </Link>
      </div>
    </React.Fragment>
  )
}

function validate_first(values) {
  const errors = {}

  if (!values.email) {
    errors.email = 'Prosím, vyplňte e-mail.'
  }

  if (!values.contract) {
    errors.contract = 'Prosím, vyplňte č. smlouvy.'
  }

  if (!values.pass1) {
    errors.pass1 = 'Prosím, vyplňte toto pole.'
  }

  if (!values.pass2) {
    errors.pass2 = 'Prosím, vyplňte toto pole.'
  }

  if (values.pass2 && values.pass1 && values.pass2 !== values.pass1) {
    errors.pass2 = 'Hesla musí být stejná.'
  }

  if (values.pass2 && values.pass1) {
    let err = passwordValidation(values.pass1)

    if (err) {
      errors.pass1 = err
    }
  }

  return errors
}

export default reduxForm({
  form: 'first_login_form',
  touchOnBlur: false,
  destroyOnUnmount: false,
  validate: validate_first,

  /*
    validate,
    onSubmitFail: errors => {

    }*/
})(FirstLoginFormFirstStep)
