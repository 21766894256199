import {
  // REQUEST_UPSELL_PORTFOLIOS,
  // RECEIVE_UPSELL_PORTFOLIOS,
  REQUEST_UPSELL_OPTIONS,
  REQUEST_UPSELL_OPTIONS_FAILED,
  RECEIVE_UPSELL_OPTIONS,
  SELECT_PORTFOLIO,
  SELECT_INSTALLATION,
  REQUEST_SUBMIT_UPSELL_STEP_1,
  RECEIVE_SUBMIT_UPSELL_STEP_1,
  RECEIVE_SUBMIT_UPSELL_STEP_1_FAILED,
  REQUEST_SUBMIT_UPSELL_STEP_2,
  RECEIVE_SUBMIT_UPSELL_STEP_2,
  RECEIVE_SUBMIT_UPSELL_STEP_2_FAILED,
  REQUEST_SUBMIT_UPSELL_STEP_3,
  RECEIVE_SUBMIT_UPSELL_STEP_3,
  RECEIVE_SUBMIT_UPSELL_STEP_3_FAILED,
  SELECT_PACKAGE_CORE,
  INC_DEVICE_TOTAL,
  DEC_DEVICE_TOTAL,
  RESET_DEVICE_TOTAL,
  FINISH_REGISTRATION,
  GO_TO_STEP_UPSELL,
} from '../actions/types'

const INITIAL_STATE = {
  loading: true,
  step: 1,
  hash1: null,
  hash2: null,
  formData: {
    step1: null,
    step2: null,
  },
  step1Payload: {},
  step2Payload: {},
  selectedPortfolio: null,
  selectedPackageCore: null,
  selectedInstallation: null,
  portfolios: [],
  options: {},
  overview: {},
  deviceTotal: 0,
  inProgress: false,
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    // case REQUEST_UPSELL_PORTFOLIOS:
    //   return {
    //     ...state,
    //     loading: true,
    //   }
    case REQUEST_UPSELL_OPTIONS_FAILED:
      return {
        ...state,
        loading: false,
      }
    // case RECEIVE_UPSELL_PORTFOLIOS:
    //   return {
    //     ...state,
    //     portfolios: action.payload,
    //     loading: false,
    //   }
    case REQUEST_UPSELL_OPTIONS:
      return {
        ...state,
        loading: true,
      }
    case SELECT_PORTFOLIO:
      let s = {
        ...state,
        selectedPortfolio: action.payload,
      }
      if (typeof action.deviceTotal === 'number')
        s.deviceTotal = action.deviceTotal
      return s
    case SELECT_INSTALLATION:
      return {
        ...state,
        selectedInstallation: action.payload,
      }
    case SELECT_PACKAGE_CORE:
      return {
        ...state,
        selectedPackageCore: action.payload,
        formData: {
          ...state.formData,
          step1: {
            ...state.formData.step1,
            package_core: action.payload,
          },
        },
      }
    case RECEIVE_UPSELL_OPTIONS:
      return {
        ...state,
        options: {
          ...state.options,
          ...action.payload,
        },
        loading: false,
        lastUpdated: new Date(),
      }
    case REQUEST_SUBMIT_UPSELL_STEP_1:
      return {
        ...state,
        loading: true,
        formData: {
          ...state.formData,
          step1: action.formData,
        },
        step1Payload: action.step1Payload,
        hash1: action.formHash,
        hash2: null,
      }
    case RECEIVE_SUBMIT_UPSELL_STEP_1:
      return {
        ...state,
        loading: false,
        overview: action.payload,
        inProgress: true,
      }
    case RECEIVE_SUBMIT_UPSELL_STEP_1_FAILED:
      return {
        ...state,
        loading: false,
      }
    case REQUEST_SUBMIT_UPSELL_STEP_2:
      return {
        ...state,
        loading: true,
        formData: {
          ...state.formData,
          // step2: action.formData,
        },
        step2Payload: action.step2Payload,
        hash2: action.formHash,
      }
    case RECEIVE_SUBMIT_UPSELL_STEP_2:
      return {
        ...state,
        loading: false,
        overview: {
          ...state.overview,
          ...action.payload,
        },
      }
    case RECEIVE_SUBMIT_UPSELL_STEP_2_FAILED:
      return {
        ...state,
        loading: false,
        hash2: null,
      }
    case REQUEST_SUBMIT_UPSELL_STEP_3:
      return {
        ...state,
        // loading: true,
      }
    case RECEIVE_SUBMIT_UPSELL_STEP_3:
      return {
        ...state,
        loading: false,
        overview: {
          ...state.overview,
          ...action.payload,
        },
        inProgress: false,
      }
    case RECEIVE_SUBMIT_UPSELL_STEP_3_FAILED:
      return {
        ...state,
        loading: false,
      }
    case GO_TO_STEP_UPSELL:
      return {
        ...state,
        step: action.payload,
      }
    case INC_DEVICE_TOTAL:
      return {
        ...state,
        deviceTotal: state.deviceTotal + 1,
      }
    case DEC_DEVICE_TOTAL:
      return {
        ...state,
        deviceTotal: state.deviceTotal > 0 ? state.deviceTotal - 1 : 0,
      }
    case RESET_DEVICE_TOTAL:
      return {
        ...state,
        deviceTotal: action.payload,
      }
    case FINISH_REGISTRATION:
      return {
        ...INITIAL_STATE,
      }

    // no default
  }

  return state
}
