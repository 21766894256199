import React from 'react'
import { useSelector } from 'react-redux'
import sanitizeHtml from 'sanitize-html'
import { ALLOWED_ATTRIBUTES, ALLOWED_TAGS } from '../conf/constants'

export default function ChosenReward(props) {
  const { reward } = props
  const content = useSelector((state) => state.translation.content)

  return (
    <div className={`subreward subreward--choosen`}>
      <h3>
        {sanitizeHtml(content['content.' + reward.title], {
          allowedTags: [],
          allowedAttributes: [],
        })}
      </h3>
      <p>
        {sanitizeHtml(content['content.' + reward.description], {
          allowedTags: [],
          allowedAttributes: [],
        })}
      </p>
      <div
        dangerouslySetInnerHTML={{
          __html: sanitizeHtml(content['content.' + reward.image], {
            allowedTags: ALLOWED_TAGS,
            allowedAttributes: ALLOWED_ATTRIBUTES,
          }),
        }}
      ></div>
      {reward.type === 'VOUCHER' && (
        <>
          {reward.usageData.voucher.isPossibleToUse && (
            <>
              <div className="subreward__voucher">
                <div>{reward.usageData.voucher.code}</div>
              </div>
              <a
                className={`btn btn--primary`}
                href={reward.usageData.followLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                Vyzvednout odměnu
              </a>
            </>
          )}
          {!reward.usageData.voucher.isPossibleToUse && (
            <button className={`btn btn--primary`} disabled={true}>
              Vyzvednuto
            </button>
          )}
        </>
      )}
    </div>
  )
}
