import React, { Component } from 'react'
//import AdminHeader from "../component/admin-header";
import { connect } from 'react-redux'
import { compose } from 'redux'
import ContractHoc from '../hoc/contract-hoc'

import AdminContent from '../layout/admin-content'
//import Loading from '../component/loading'

import DigiModalContext from '../context/digi-modal-context'
// import Reward from '../component/reward'

import { requestRewards } from '../actions/loyalty'
// import Loading from '../component/loading'

// import { postHeight } from '../utils/functions'
// import sanitizeHtml from 'sanitize-html'

import imagePartners1 from '../assets/images/image_partners_1.png'
import imagePartners3 from '../assets/images/image_partners_3.png'
import imagePartnersPhilips from '../assets/images/image_partners_philips.jpg'
import imagePartnersFitcann from '../assets/images/image_partners_fitcann.jpg'
import imagePartnersHopSport from '../assets/images/image_partners_hop_sport.jpg'
import imagePartnersAlbi from '../assets/images/image_partners_albi.jpg'
import imagePartnersLogo1 from '../assets/images/image_partners_1_logo.png'
import imagePartnersLogo3 from '../assets/images/image_partners_3_logo.png'
import imagePartnersPhilipsLogo from '../assets/images/image_partners_philips_logo.png'
import imagePartnersFitcannLogo from '../assets/images/image_partners_fitcann_logo.png'
import imagePartnersHopSportLogo from '../assets/images/image_partners_hop_sport_logo.png'
import imagePartnersAlbiLogo from '../assets/images/image_partners_albi_logo.png'
import { postHeight } from '../utils/functions'

class LoyaltyPartners extends Component {
  static contextType = DigiModalContext

  render() {
    postHeight('.loyalty__partners__item h2')
    postHeight('.loyalty__partners__desc')

    const { content } = this.props
    return (
      <AdminContent
        advertisementTarget="profile.personal.data.banner"
        title="Věrnostní program"
        subtitle={content['content.loyalty.partners.title']}
        link="/ucet/vernostni-program/prehled"
      >
        <div className="row">
          <div className="col col--is--12 col--big">
            <div className="admin__inner">
              <div className="loyalty__partners">
                <div className="row">
                  <div className="col col--sm--4">
                    <div className="loyalty__partners__item">
                      <h2>LAMA energy: Chytrá sleva na plyn a elektřinu</h2>
                      <img src={imagePartnersLogo1} alt="" />
                      <p className="loyalty__partners__desc">
                        Ušetřete s Lamou 50 % nákladů na stálou platbu energií.
                      </p>
                      <a
                        href="https://telly.cz/le/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="btn btn--primary"
                      >
                        Zjistit více
                      </a>
                    </div>
                    <div className='loyalty__partners__image'>
                    <img src={imagePartners1} alt="" />
                    </div>
                  </div>
                  <div className="col col--sm--4">
                    <div className="loyalty__partners__item">
                      <h2>Internet a mobil: sleva až 200 Kč měsíčně</h2>
                      <img src={imagePartnersLogo3} alt="" />
                      <p className="loyalty__partners__desc">
                        Zkombinujte TV balíček s internetem od Telly a mobilním
                        tarifem od LAMA mobile a získejte slevu až 200 Kč
                        měsíčně.
                      </p>
                      <a
                        href="https://telly.cz/kombinace/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="btn btn--primary"
                      >
                        Zjistit více
                      </a>
                    </div>
                    <div className='loyalty__partners__image'>
                    <img src={imagePartners3} alt="" />
                  </div>
                  </div>
                  <div className="col col--sm--4">
                    <div className="loyalty__partners__item">
                      <h2>PHILIPS: Sleva 20 % na vše v e-shopu</h2>
                      <img src={imagePartnersPhilipsLogo} alt="" />
                      <p className="loyalty__partners__desc">
                        Využijte zvýhodněných cen v oficiálním e-shopu Philips.cz
                      </p>
                      <button
                        className="btn btn--primary"
                        onClick={() => {
                          this.context.toggleDigiModal(
                            content['content.loyalty.partners.philips.content'],
                            '',
                            true
                          )
                        }}
                      >
                        Zjistit více
                      </button>
                    </div>
                    <div className='loyalty__partners__image'>
                    <img src={imagePartnersPhilips} alt="" />
                  </div>
                  </div>
                  <div className="col col--sm--4">
                    <div className="loyalty__partners__item">
                      <h2>20 % na celý sortiment značky konopné péče Fitcann</h2>
                      <img src={imagePartnersFitcannLogo} alt="" />
                      <p className="loyalty__partners__desc">
                        Fitcann je konopná péče pro sportovce. Bio oleje v nativní formě.
                      </p>
                      <button
                        className="btn btn--primary"
                        onClick={() => {
                          this.context.toggleDigiModal(
                            content['content.loyalty.partners.fitcann.content'],
                            '',
                            true
                          )
                        }}
                      >
                        Zjistit více
                      </button>
                    </div>
                    <div className='loyalty__partners__image'>
                    <img src={imagePartnersFitcann} alt="" />
                  </div>
                  </div>
                  <div className="col col--sm--4">
                    <div className="loyalty__partners__item">
                      <h2>Albi: Sleva 10 % na vše s logem Albi</h2>
                      <img src={imagePartnersAlbiLogo} alt="" />
                      <p className="loyalty__partners__desc">
                        Využijte zvýhodněných cen na Albi produkty
                      </p>
                      <button
                        className="btn btn--primary"
                        onClick={() => {
                          this.context.toggleDigiModal(
                            content['content.loyalty.partners.albi.content'],
                            '',
                            true
                          )
                        }}
                      >
                        Zjistit více
                      </button>
                    </div>
                    <div className='loyalty__partners__image'>
                    <img src={imagePartnersAlbi} alt="" />
                  </div>
                  </div>
                  <div className="col col--sm--4">
                    <div className="loyalty__partners__item">
                      <h2>Hop-sport: Sleva 10 % na hrazdy, podložky na cvičení, masážní koule, expandéry a gumy na cvičení</h2>
                      <img src={imagePartnersHopSportLogo} alt="" />
                      <p className="loyalty__partners__desc">
                        Využijte zvýhodněných cen na Hop-sport.cz
                      </p>
                      <button
                        className="btn btn--primary"
                        onClick={() => {
                          this.context.toggleDigiModal(
                            content['content.loyalty.partners.hopsport.content'],
                            '',
                            true
                          )
                        }}
                      >
                        Zjistit více
                      </button>
                    </div>
                    <div className='loyalty__partners__image'>
                    <img src={imagePartnersHopSport} alt="" />
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AdminContent>
    )
  }
}

function mapStateToProps(state) {
  return {
    content: state.translation.content,
  }
}

export default compose(
  connect(mapStateToProps, { requestRewards }),
  ContractHoc
)(LoyaltyPartners)
