import React from 'react'
import moment from 'moment'
import { dtApiText } from '../../utils/functions'

const UserBasicInfo = ({ user = {} }) => {
  return (
    <React.Fragment>
      <h2>Základní informace</h2>
      <div className="row">
        <div className="col col--is--12 col--md--6">
          <ul className="list">
            <li className="list__row">
              <div className="list__col list__label">Jméno</div>
              <div className="list__col list__value">
                {user.name ? user.name : <span>-</span>}
              </div>
            </li>
            <li className="list__row">
              <div className="list__col list__label">Příjmení</div>
              <div className="list__col list__value">
                {user.surname ? user.surname : <span>-</span>}
              </div>
            </li>
            {user.birthDate && (
              <li className="list__row">
                <div className="list__col list__label">Datum narození</div>
                <div className="list__col list__value">
                  {moment(user.birthDate).format('L')}
                </div>
              </li>
            )}
          </ul>
        </div>
        <div className="col col--is--12 col--md--6 melt--to--md">
          <ul className="list">
            <li className="list__row">
              <div className="list__col list__label">Typ:</div>
              <div className="list__col list__value">
                {user.type ? dtApiText(user.type) : <span>-</span>}
              </div>
            </li>
            {user.type === 'company' && (
              <li className="list__row">
                <div className="list__col list__label">IČ:</div>
                <div className="list__col list__value">
                  {user.companyNumber ? user.companyNumber : <span>-</span>}
                </div>
              </li>
            )}
            {user.type === 'company' && (
              <li className="list__row">
                <div className="list__col list__label">DIČ:</div>
                <div className="list__col list__value">
                  {user.vatNumber ? user.vatNumber : <span>-</span>}
                </div>
              </li>
            )}
          </ul>
        </div>
      </div>
    </React.Fragment>
  )
}

export default UserBasicInfo
