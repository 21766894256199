import React from 'react'
import { Field, reduxForm } from 'redux-form'

import { Link } from 'react-router-dom'
import IntroHeader from '../component/intro-header'

import {
  renderField,
  renderTooglePassword,
  emailValidation,
} from './../utils/form'
import Loading from '../component/loading.js'

const LoginForm = (props) => {
  const { handleSubmit, submitting, content } = props

  return (
    <React.Fragment>
      {submitting && <Loading />}
      <form onSubmit={handleSubmit}>
        <IntroHeader label={content && content['content.login.user']} />

        <div className="form__row">
          <div className="form__item">
            <label htmlFor="username">Váš e-mail:</label>
          </div>
          <div className="form__item">
            <Field
              id="username"
              name="username"
              component={renderField}
              type="text"
              placeholder=""
              validate={[emailValidation]}
            />
          </div>
        </div>
        <div className="form__row">
          <div className="form__item">
            <label htmlFor="password">Heslo:</label>
          </div>
          <div className="form__item">
            <Field
              id="password"
              name="password"
              component={renderTooglePassword}
              type="password"
              placeholder=""
            />
          </div>
        </div>

        <div className="intro__buttons">
          <button
            disabled={submitting}
            className="btn btn--primary"
            type="submit"
          >
            Přihlásit se
          </button>
          <Link className="intro__forgotten" to="/zapomenute-heslo">
            Zapomněl jsem přihlašovací údaje
          </Link>
        </div>
        <div className="intro__buttons__link">
          <div>
            <Link
              className="link-primary a--arrow black"
              to={'/prvni-prihlaseni'}
            >
              Jsem zákazníkem a ještě nemám heslo
            </Link>
          </div>
          <div>
            <Link className="link-primary a--arrow black" to={'/registrace'}>
              Chci se stát zákazníkem Telly
            </Link>
          </div>
        </div>
      </form>
    </React.Fragment>
  )
}

function validate(values) {
  const errors = {}

  if (!values.username) {
    errors.username = 'Prosím, vyplňte e-mail.'
  }

  if (!values.password) {
    errors.password = 'Prosím, vyplňte heslo.'
  }

  return errors
}

export default reduxForm({
  form: 'form_login',
  touchOnBlur: false,
  validate,
})(LoginForm)
