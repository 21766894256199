import React, { Component } from 'react'
import { connect } from 'react-redux'
// import Loading from './loading'
// import sanitizeHtml from 'sanitize-html';

import { getAdvertisement } from '../actions/app'

// import { ALLOWED_TAGS, ALLOWED_ATTRIBUTES } from '../conf/constants'

class Banner extends Component {
  constructor(props) {
    super(props)
    this.state = {
      //position: this.props.position
    }
  }

  componentDidMount() {
    this.props.getAdvertisement(this.props.target, this.props.position)
    /*
        this.props.getAdvertisement(this.props.target, this.props.position).then( data => {
            this.setState({
                content: data.content
            })
        })*/
  }

  render() {
    let content = null

    if (
      this.props.advertisement[this.props.target] &&
      this.props.advertisement[this.props.target][this.props.position]
    ) {
      content = this.props.advertisement[this.props.target][this.props.position]
        .content
    }

    if (!content) {
      return null
    }

    return (
      <div
        className={`banner banner--${this.props.position}`}
        dangerouslySetInnerHTML={{ __html: content }}
      />
    )
  }
}

function mapStateToProps(state) {
  return {
    advertisement: state.advertisement,
  }
}

export default connect(
  mapStateToProps,
  {
    getAdvertisement,
  }
)(Banner)
